import { useState, useEffect, useRef } from "react";
import {
  HeaderWrapper,
  AltHeaderWrapper,
} from "../Components/Core/Core.styles";
import PageHeader from "../Components/PageHeader/PageHeader";
import { DescFilter } from "../Components/Pagination/Pagination.styles";
import {
  PaginationWrapper,
  PaginationHeader,
  PageDropdown,
} from "../Components/Pagination/Pagination.styles";
import {
  UnrecoModalWrapper,
  ModalRow,
  ModalTd,
} from "../Components/ModalTable/ModalTable.styles";
import ModalTableUnreco from "../Components/ModalTableUnreco/ModalTableUnreco";
import {
  TableHead,
  TableHeader,
  HeaderRow,
  ConfirmButton,
} from "../Components/ModalTableUnreco/ModalTableUnreco.styles";
import Spinner from "../Assets/SpinBrokerSpin.gif";
import Select from "react-select";
import "../index.css";
import { prototype } from "react-modal";
const baseURL = process.env.REACT_APP_API_URL;
const dayjs = require("dayjs");
const axios = require("axios").default;

const UnrecoTrans = (props) => {
  const unrecoData = props.data;
  const [data, setData] = useState(props.data);
  const [searchData, setSearchData] = useState();
  const [commData, setCommData] = useState();
  const [loading, setLoading] = useState(false);
  const [filterLender, setFilterLender] = useState("");
  const [filterDate, setFilterDate] = useState("");
  const [filterAmount, setFilterAmount] = useState("");
  const token = window.localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [lenderData, setLenderData] = useState("");

  const [lender, setLender] = useState("Filter by Lender");
  const lenderList = [];

  const [limit, setLimit] = useState(99999);

  function getComms() {
    axios
      .get(baseURL + `/partner-commissions/99999/1/search`, {
        headers: headers
      })
      .then((response) => {
        setCommData(response.data, () => {
          return commData;
        });
        console.log(commData);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  // FILTER LENDER DROP TO ONLY ONE LENDER
  commData?.data?.data?.forEach((comm) => {
    let flag = true;
    for (let i = 0; i < lenderList.length; i++) {
      if (lenderList[i].value === comm.lender_name) {
        flag = false;
        break;
      }
    }
    if (flag)
      lenderList.push({ label: comm.lender_name, value: comm.lender_name });
  });

  // function filterLenders(data) {
  //   return data?.filter((value, index) => data.indexOf(value) === index)
  // }

  // const lenderArray = filterLenders(lenderList);

  // lenderArray.forEach((lender) => {
  //   lenderArray.push({ label: lender.lender_name, value: lender.lender_name })
  // })

  // const filteredLenderArray = [];

  // const lenders = lenderArray.forEach((lender) => {
  //   filteredLenderArray.push({ label: lender.lender_name, value: lender.lender_name });
  // })
  // FILTER LENDER DROPDOWN TO ONLY ONE LENDER

  useEffect(() => {
    getComms();
  }, []);

  useEffect(() => {
    setLoading(true);
    function getUnreco() {
      axios
        .get(baseURL + `/get-unreconcilied-payments/${limit}/1`, {
          headers: headers
        })
        .then((response) => {
          setData(response.data);
          console.log(data);
        });
    }
    getUnreco();
    setLoading(false);
  }, [limit, token]);

  const handleLender = (e) => {
    const value = e.value;
    setLender(value);
    setFilterLender(value);
    setSearchLender(value);
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      childRef.current.getSchedDate();
    } else {
      return;
    }
  };

  const [searchLender, setSearchLender] = useState(filterLender);
  const [searchDate, setSearchDate] = useState(filterDate);
  const [searchAmount, setSearchAmount] = useState(filterAmount);

  const childRef = useRef();
  
  const resetParams = () => {
    setLender("");
    setFilterLender("");
    setSearchLender("");
    setFilterAmount("");
    setSearchAmount("");
    setFilterDate("");
    setSearchDate("");
  };

  function resetFilter() {
    resetParams();
    childRef.current.getSchedDate();
    getComms();
  }

  if (!data) return null;

  const headerWidth = {
    header: {
      width: "100%",
    },
    id: {
      width: "10%",
    },
    date: {
      width: "15%",
    },
    desc: {
      width: "50%",
    },
    amount: {
      width: "25%",
    },
  };

  if (loading || !data) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "0 auto",
        }}
      >
        <img src={Spinner} style={{ width: "4rem", height: "auto" }} />
      </div>
    );
  }

  const index = data.data.findIndex((tx) => tx.tx_id == props.id);
  

  return (
    <div>
      <AltHeaderWrapper>
        <PageHeader
          header={`Reconciling Transaction ID ${props.id}`}
          blurb="Any bank transactions we were unable to automatically reconcile must be done manually. "
        />
      </AltHeaderWrapper>
      <UnrecoModalWrapper>
        <TableHead>
          <HeaderRow style={headerWidth.header}>
            <TableHeader style={headerWidth.id}>ID</TableHeader>
            <TableHeader style={headerWidth.date}>Transaction Date</TableHeader>
            <TableHeader style={headerWidth.desc}>Description</TableHeader>
            <TableHeader style={headerWidth.amount}>Amount</TableHeader>
          </HeaderRow>
        </TableHead>
        <ModalRow>
          <ModalTd style={headerWidth.id}>{props.id}</ModalTd>
          <ModalTd style={headerWidth.date}>
            {dayjs(data.data[index].date).format("DD/MM/YYYY")}
          </ModalTd>
          <ModalTd style={headerWidth.desc}>{data.data[index].text}</ModalTd>
          <ModalTd style={headerWidth.amount}>
            {data.data[index].amount}
          </ModalTd>
        </ModalRow>
      </UnrecoModalWrapper>
      <HeaderWrapper>
        <PageHeader header="Payments" />
      </HeaderWrapper>
      <PaginationWrapper style={{ width: "100%",display:"grid",gridTemplateColumns: "20% 20% 20% 40%",alignItems: "center"}}>
        <div>
        <PaginationHeader>Lender:</PaginationHeader>
        <PageDropdown>
          <Select
            placeholder={lender}
            value={filterLender}
            options={lenderList}
            onChange={handleLender}
            onKeyDown={handleEnter}
          />
        </PageDropdown>
        </div>
        
        <div style={{fontSize: '15px', fontWeight: 700, color: '#777777', verticalAlign: 'middle',width: 'min-content'}}>
        <PaginationHeader>Expected Amount:</PaginationHeader>
          <PageDropdown>
            <DescFilter
              id="amountFilter"
              small
              placeholder="Filter by Expected Amount"
              type="text"
              value={filterAmount}
              onChange={(event) => setFilterAmount(event.target.value)}
              onKeyDown={handleEnter}
            />
          </PageDropdown>
        </div>

        <div>
          <PaginationHeader>Date:</PaginationHeader>
          <PageDropdown>
            <DescFilter
              id="dateFilter"
              small
              placeholder="Filter by Date"
              type="date"
              value={filterDate}
              onChange={(event) => {
                setFilterDate(event.target.value);
                setSearchDate(event.target.value);
              }}
              onKeyDown={handleEnter}
            />
          </PageDropdown>
        </div>
        <div style={{marginTop:"40px"}}>
          <ConfirmButton date onClick={() => childRef.current.getSchedDate()}>
            Apply Filter
          </ConfirmButton>
          <ConfirmButton date onClick={resetFilter}>
            Reset Filter
          </ConfirmButton>
        </div>
      </PaginationWrapper>
      <UnrecoModalWrapper>
        <ModalTableUnreco
          ref={childRef}
          filterLender={filterLender}
          filterAmount={filterAmount}
          filterDate={filterDate}
          data={data}
          commData={commData}
          searchData={searchData}
          id={props.id}
          index={index}
          searchLender={searchLender}
          searchDate={searchDate}
          searchAmount={searchAmount}
        />
      </UnrecoModalWrapper>
    </div>
  );
};

export default UnrecoTrans;
