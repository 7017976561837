import {
  CardDiv,
  CardHeader,
  CardP,
  CardButton,
  ButtonLink,
} from "./Cards.styles";
import React from "react";

const Cards = (props) => {
  return (
    <CardDiv>
          <CardHeader>{props.header}</CardHeader>
          <CardP>{props.blurb}</CardP>
          <CardButton>
            <ButtonLink to={props.link}>Continue</ButtonLink>
          </CardButton>
    </CardDiv>
  );
};

export default Cards;
