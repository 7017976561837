import { useState, useEffect } from "react";
import { HeaderWrapper } from "../Components/Core/Core.styles";
import PageHeader from "../Components/PageHeader/PageHeader";
import TransactionTableAll from "../Components/TransactionTableAll/TransactionTableAll";
import Select from "react-select";
import {
  PaginationWrapper,
  PaginationHeader,
  PageDropdown,
  DescFilter,
} from "../Components/Pagination/Pagination.styles";
const baseURL = process.env.REACT_APP_API_URL;
const axios = require("axios").default;
const AllTrans = () => {
  const [data, setData] = useState(null);
  const [filter, setFilter] = useState("");
  const page = 1;
  const limit = 99999;
  const token = window.localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  useEffect(() => {
    axios
      .get(baseURL + `/payments/${limit}/${page}`, {
        headers: headers,
      })
      .then((response) => {
        setData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [limit, page, token]);

  if (!data) return null;

  return (
    <>
      <HeaderWrapper>
        <PageHeader
          header="All Bank Transactions"
          blurb="View all bank transactions we have in our database. You can search and filter these transactions to help you."
        />
      </HeaderWrapper>
      <PaginationWrapper>
        <PaginationHeader>Description Contains Word: </PaginationHeader>
        <PageDropdown>
          <DescFilter
            placeholder="E.g Prospa"
            type="text"
            value={filter}
            onChange={(event) => setFilter(event.target.value)}
          />
        </PageDropdown>
      </PaginationWrapper>
      <TransactionTableAll
        limit={limit}
        page={page}
        inputFilter={filter}
        data={data}
      />
    </>
  );
};

export default AllTrans;
