import styled from "styled-components";

export const TableWrapper = styled.div`
  /* display: flex; */
  display: inline-block;
  width: 100%;
  height: 650px;
  overflow-y: scroll;
  
  @media screen and (max-width: 1200px) {
    overflow-x: scroll;
      /* shadow effects for scrolling are below */
      background-image: linear-gradient(to right, white, white),
        linear-gradient(to right, white, white),
        linear-gradient(
          to right,
          rgba(179, 179, 179, 0.4),
          rgba(255, 255, 255, 0)
        ),
        linear-gradient(
          to left,
          rgba(179, 179, 179, 0.4),
          rgba(255, 255, 255, 0)
        );
      /* Shadows */
      /* Shadow covers */
      background-position: left center, right center, left center, right center;
      background-repeat: no-repeat;
      background-color: white;
      background-size: 30px 100%, 30px 100%, 20px 100%, 20px 100%;
      background-attachment: local, local, scroll, scroll;
  }
`;

export const Table = styled.table`
  width: 94%;
  /* max-width: 94%; */
  margin: 0 auto;

  @media screen and (max-width: 1200px) {
    width: 1200px;
    margin: 0 2rem;
  }
`;

export const TableBody = styled.tbody`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
`;

export const TableHead = styled.thead`
  width: 100%;
  display: flex;
  padding: 5px 0;
  border-bottom: 1px solid #e1dede;
  position: sticky;
  background-color: white;
`;

export const HeaderRow = styled.tr`
  width: 100%;
  display: flex;
`;

export const TableHeader = styled.th`
  display: flex;
  justify-content: flex-start;
  color: #777777;
  height: auto;
`;

export const TransRow = styled.tr`
  width: 100%;
  display: flex;
  border-bottom: 1px solid #e1dede;
  padding: 10px 0px;
`;

export const TransTd = styled.td`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  vertical-align: middle;
  align-items: center;
  margin-left: 0rem;
  color: #777777;
  font-size: 15px;
`;

export const TableButton = styled.button`
  height: 25px;
  width: 130px;
  color: white;
  background-color: #1572de;
  border-radius: 2.5px;
  font-size: 13px;
  outline: none;
  border: none;
  cursor: pointer;

  :active {
    border: 1px solid #d6d6d6;
  }
`;
