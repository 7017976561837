import React, { useState, useEffect } from "react";
import {
  TableWrapper,
  Table,
  TableHead,
  HeaderRow,
  TableHeader,
  TableBody,
  TransRow,
  TransTd,
  ModalButtonWrapper,
  ConfirmButton,
  DenyButton,
  NotesWrapper,
  NotesBlurb,
  ConfirmWrapper
} from "../ModalTable/ModalTable.styles";
import {
  NoRecoDiv,
  NoRecoBlurb,
} from "../ModalTableUnreco/ModalTableUnreco.styles";
const baseURL = process.env.REACT_APP_API_URL;
const dayjs = require("dayjs");
const axios = require("axios").default;
const ModalTable = (props) => {
  const [tableData, setTableData] = useState(props.data);
  const index = props.index;
  const id = props.id.tx_id;

  const [notes, setNotes] = useState("");
  const token = window.localStorage.getItem("token")
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  useEffect(() => {
    function getNotes() {
      axios
        .get(baseURL + `/get-notes/${id}`, {
          headers: headers
        })
        .then((response) => {
          const res = response.data.data;
          setNotes(res[0].note);
          console.log(res[0].note);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    getNotes();
  }, [token]);

  // const [transId, setTransId] = useState(props.id);
  const tableWidth = {
    created: {
      width: "10%",
    },
    lead: {
      width: "10%",
    },
    sale: {
      width: "10%",
    },
    comm: {
      width: "30%",
    },
    date: {
      width: "10%",
    },
    lender: {
      width: "30%",
    },
  };

  // if (!tableData || undefined) return null;
  const [confirmed, setConfirmed] = useState(false);
  const [cancelled, setCancelled] = useState(false);
  function ConfirmTransaction() {
    axios
      .post(baseURL + `/confirm-match/${id}`, {
        headers: headers
      })
      .then(function (response) {
        console.log(response);
        setConfirmed(true);
        setCancelled(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function DenyTransaction() {
    axios
      .post(baseURL + `/cancel-match/${id}`, {
        headers: headers
      })
      .then(function (response) {
        console.log(response);
        setCancelled(true);
        setConfirmed(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const [commissions, setCommissions] = useState("")
  
  useEffect(() => {
   function getCommissions() {
     axios
       .get(baseURL + `/all-partner-commissions/100/1`, {
         headers: headers
       })
       .then((response) => {
         setCommissions(response.data);
       })
       .catch(function (error) {
         console.log(error);
       });
   }
   getCommissions();
   splitSearch();
  }, [token])

  function splitSearch() {
    if (commissions.data !== undefined) {
      commissions.data.forEach((comm) => {

      })
    }
  }
  if (!commissions) return null;

  const splits = JSON.parse(tableData[index].split);
  const commId = JSON.parse(tableData[index].commission_id);

  // THIS FILTERS THROUGH THE SPLITS, USES THE COMM_ID TO GRAB THAT DATA FROM COMMISSIONS AND RETURNS IT TO AN ARRAY
  const commArray = [];
  if(splits) {
    for(let i = 0; i < commissions.data.length; i++) {
      for(let x = 0; x < splits.length; x++) {
        for(const split in commissions.data[i]) {
          if(commissions.data[i][split] == splits[x]) {
            commArray.push(commissions.data[i])
          }
        }
      }
    }
  } if(commissions) {
    for(let i = 0; i < commissions.data.length; i++) {
      for(const comm in commissions.data[i]) {
        if(commissions.data[i][comm] == commId) {
          commArray.push(commissions.data[i])
        } 
      }
    }
  } if(splits && commissions === 0) {
    return (
        <NoRecoDiv>
          <NoRecoBlurb>This transaction hasn't been reconciled.</NoRecoBlurb>
        </NoRecoDiv>
    )};
  

  return (
    <>
      <TableWrapper>
        <Table>
          <TableHead>
            <HeaderRow>
              <TableHeader style={tableWidth.created}>Created</TableHeader>
              <TableHeader style={tableWidth.lead}>Lead Ref</TableHeader>
              <TableHeader style={tableWidth.sale}>Sale Ref</TableHeader>
              <TableHeader style={tableWidth.comm}>
                Expected Commission
              </TableHeader>
              <TableHeader style={tableWidth.date}>When</TableHeader>
              <TableHeader style={tableWidth.lender}>Lender</TableHeader>
            </HeaderRow>
          </TableHead>
          <TableBody>
            {commArray != null &&
              commArray.map((split) => (
                <TransRow>
                  <TransTd style={tableWidth.created}>
                    {dayjs(split.created).format("DD/MM/YYYY")}
                  </TransTd>
                  <TransTd style={tableWidth.lead}>{split.lead_ref}</TransTd>
                  <TransTd style={tableWidth.sale}>{split.sale_ref}</TransTd>
                  <TransTd style={tableWidth.comm}>
                    {split.commission_expected}
                  </TransTd>
                  <TransTd style={tableWidth.date}>
                    {dayjs(split.sheduled_date).format("DD/MM/YYYY")}
                  </TransTd>
                  <TransTd style={tableWidth.lender}>
                    {split.lender_name}
                  </TransTd>
                </TransRow>
              ))}
            {commArray == 0 ? (
              <NoRecoDiv>
                <NoRecoBlurb>
                  This transaction hasn't been reconciled.
                </NoRecoBlurb>
              </NoRecoDiv>
            ) : (
              ""
            )}
          </TableBody>
          {commArray != 0 ? (
            <ModalButtonWrapper>
              <ConfirmButton onClick={ConfirmTransaction}>
                Yes, Confirmed Correct
              </ConfirmButton>
              <DenyButton onClick={DenyTransaction}>
                No, Unreconcile Them
              </DenyButton>
              {confirmed ? (
                <ConfirmWrapper style={{ color: "green" }}>
                  You have confirmed ✔
                </ConfirmWrapper>
              ) : null}
              {cancelled ? (
                <ConfirmWrapper style={{ color: "#bf3131" }}>
                  You have unreconciled 🗙
                </ConfirmWrapper>
              ) : null}
            </ModalButtonWrapper>
          ) : (
            ""
          )}
          {notes ? (
            <NotesWrapper>
              <NotesBlurb>Transaction Notes: <br/> <br/>{notes}</NotesBlurb>
            </NotesWrapper>
          ) : null}
        </Table>
      </TableWrapper>
    </>
  );
};

export default ModalTable;
