import styled from "styled-components";

export const PaginationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 500px;
  height: 30px;
  margin: 2rem 0rem 2rem 4rem;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
  padding: 0px 10px 10px;
  flex-flow: row-wrap;

  @media screen and (max-width: 1200px) {
    width: auto;
    height: 100%;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0rem 1rem;
  }
`;

export const PaginationHeader = styled.h2`
  font-size: 15px;
  font-weight: 700;
  color: #777777;
  vertical-align: middle;
`;

export const PageDropdown = styled.div`
margin: 0 auto;
min-width: 70px;

@media screen and (max-width: 1200px) {
  margin: 0;
}
`

export const DescFilter = styled.input`
  min-width: ${props => props.small ? "100px" : "250px"};
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
  padding: 4px 10px;
  color: hsl(0, 0%, 50%);

  :hover {
    cursor: text;
  }

  ::placeholder {
    color: #d6d6d6;
  }

  :focus {
    border-color: #2684ff;
    box-shadow: 0 0 0 1px #2684ff;
  }
`;

export const LenderDropdown = styled.select`
  min-width: ${(props) => (props.small ? "100px" : "250px")};
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
  padding: 4px 10px;
  color: hsl(0, 0%, 50%);

  :hover {
    cursor: text;
  }

  ::placeholder {
    color: #d6d6d6;
  }

  :focus {
    border-color: #2684ff;
    box-shadow: 0 0 0 1px #2684ff;
  }
`;