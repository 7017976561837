//functional component
import React from 'react';
import { useEffect, useState } from 'react';

const TransactionReconHistorySeach = (props) => {
    return (
        <>
            <div style={searchForm.OuterDiv}>
                <div style={searchForm.divTextInput}>
                    <input style={searchForm.textInput} type="text" name="keyword_search" id="keyword_search" placeholder="Description/Amount" />
                </div>
                <div style={searchForm.DivFilterSearch}>
                    <input style={searchForm.searchButton} type="button" name="Search" value="Search" onClick={props.searchReconList} />
                </div>
            </div>
        </>
    )
}


const searchForm = {
    OuterDiv: {
      display: "flex",
      padding: "0% 3% 2% 3%",
    },
    divTextInput: {
      width: "500px",
   
    },
    textInput: {
      width: "90%",
      padding: "8px"
    },
    DivFilterSearch:{
      width:"100px"
    },
    searchButton:{
      padding: "8px"
    }
    
  }



export default TransactionReconHistorySeach;





