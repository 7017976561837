// wrirte react functional component
import React, { useState, useEffect } from "react";
import SpinnerWheel from "../../Assets/SpinBrokerSpin.gif";

//react functional component
const Spinner = (props) => {

    const [display] = useState(props.display);

    return (
        <div style={{
                  display: display,
                  justifyContent: "center",
                  margin: "auto",
                  height: "100%",
                  width: "100%",
            }}>
            <img src={SpinnerWheel} alt="spinner" style={{ width: "4rem", height: "auto" }} />
        </div>
    );

};

export default Spinner;
