import styled from "styled-components";

export const TableWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 850px;
  overflow-y: scroll;

`;

export const Table = styled.table`
  width: 100%;
  max-width: 94%;
  margin: 0 auto;
`;

export const TableBody = styled.tbody`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
`;

export const TableHead = styled.thead`
  width: 100%;
  display: flex;
  padding: 5px 0;
  border-bottom: 1px solid #e1dede;
`;

export const HeaderRow = styled.tr`
  width: 100%;
  display: flex;
`;

export const TableHeader = styled.th`
display: flex;
justify-content: flex-start;
color: #777777;
height: auto;
`;

export const TransRow = styled.tr`
  width: 100%;
  display: flex;
  border-bottom: 1px solid #e1dede;
  padding: 10px 0px;
`;

export const TransTd = styled.td`
width: 100%;
  display: flex;
  justify-content: flex-start;
  vertical-align: middle;
  align-items: center;
  margin-left: 0rem;
  color: #777777;
  font-size: 15px;
`;

export const TableButton = styled.button`
  height: 25px;
  width: ${({ other }) => (other ? "80px" : "130px")};
  margin: 0px 5px;
  color: white;
  background-color: #1572de;
  border-radius: 2.5px;
  font-size: 13px;
  outline: none;
  border: none;
  cursor: pointer;

  :hover {
    background-color: #0c55ab;
    transition: ease 0.2s;
  }

  :active {
    border: 1px solid #d6d6d6;
    border-radius: 4px;
  }
`;
