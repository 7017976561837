// wrirte react functional component
import React, { useState, useEffect } from "react";

//react functional component
const NoData = () => {


    const [message, setMessage] = useState('');

    setTimeout(() => {
        setMessage('No data found');
    }, 2000);


    return (
        <div style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "auto",
                  height: "100%",
                  width: "100%",
                  paddingTop: "1%",
            }}>
                {message}
        </div>
    );
};

export default NoData;




