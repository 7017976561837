import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useCallback,
  useRef,
} from "react";
import Modal from "react-modal";
import PageHeader from "../PageHeader/PageHeader";
import SpinnerUtit from "../Utility/ProgressWheel";
import NoData from "../Utility/NoData";
import {
  TableWrapper,
  Table,
  TableHead,
  TopHeaderRow,
  TopTableHeader,
  TableHeader,
  TableBody,
  TransRow,
  TransTd,
  ModalButtonWrapper,
  ConfirmButton,
  TransCheckbox,
  ConfirmWrapper,
  ConfirmHeaderWrapper,
  ConfirmHeader,
  ConfirmTotal,
  ConfirmBlurb,
  CheckContainer,
  ConfirmGreen,
} from "./ModalTableUnreco.styles";
import {
  ModalHeaderWrapper,
  ModalBlurbWrapper,
  ModalRow,
  ModalTd,
} from "../ModalTable/ModalTable.styles";
import ModalTableUnrecoConfirm from "../ModalTableUnrecoConfirm/ModalTableUnrecoConfirm";
import TxNotes from "../TxNotes/TxNotes";
import CommisionNotes from "../CommisionNotes/CommisionNotes";
import { CloseButton } from "../Modal/Modal.styles.js";
import Spinner from "../../Assets/SpinBrokerSpin.gif";
const baseURL = process.env.REACT_APP_API_URL;
const dayjs = require("dayjs");
const axios = require("axios").default;



const ModalTableUnreco = forwardRef((props, ref, passLenderList) => {

  const [searchData, setSearchData] = useState();
  const [searchLender, setSearchLender] = useState(undefined);
  const [searchDate, setSearchDate] = useState(undefined);
  const [searchAmount, setSearchAmount] = useState(undefined);
  const [comm, setComm] = useState();
  const [commData, setCommData] = useState(props.commData);

  const token = window.localStorage.getItem("token")
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  
  function getSchedDate(lender, amount, date) {
    // not using // 

    setSearchLender(props.searchLender, () => {
      return searchLender;
    });
    setSearchAmount(props.searchAmount, () => {
      return searchAmount;
    });
    setSearchDate(props.searchDate, () => {
      return searchDate;
    });
    axios
      .get(baseURL + `/partner-commissions/99999/1/search`, {
        params: {
          scheduled_date: searchDate !== "" ? searchDate : undefined,
          commission_expected: searchAmount !== "" ? searchAmount : undefined,
          lender_name: searchLender !== "" ? searchLender : undefined,
        },
        headers: headers,
      })
      .then((response) => {
        setComm(response.data.data, () => {
          return comm;
        });
        setTimeout(setLoading(false), 2000);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function getSchedDate() {
    
    // using function // 

    axios
      .get(baseURL + `/partner-commissions/99999/1/search`, {
        params: {
          scheduled_date: searchDate !== "" ? searchDate : undefined,
          commission_expected: searchAmount !== "" ? searchAmount : undefined,
          lender_name: searchLender !== "" ? searchLender : undefined,
        },
        headers: headers,
      })
      .then((response) => {
        setComm(response.data.data, () => {
          return comm;
        });
        setTimeout(setLoading(false), 2000);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useImperativeHandle(ref, () => ({
    getSchedDate() {
    setSearchLender(props.filterLender, () => {
      return searchLender;
    });
    setSearchAmount(props.filterAmount, () => {
      return searchAmount;
    });
    setSearchDate(props.filterDate, () => {
      return searchDate;
    });
    axios
      .get(
        baseURL + `/partner-commissions/99999/1/search`, {
          params: {
            scheduled_date: searchDate !== "" ? searchDate : undefined,
            commission_expected: searchAmount !== "" ? searchAmount : undefined,
            lender_name: searchLender !== "" ? searchLender : undefined,
          }, 
          headers: headers
          })
      .then((response) => {
        setComm(response.data.data, () => {
          return comm;
        });
        setTimeout(setLoading(false), 2000);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  }))

useEffect(() => {
      setSearchLender(props.filterLender, () => {
        return searchLender;
      });
      setSearchAmount(props.filterAmount, () => {
        return searchAmount;
      });
      setSearchDate(props.filterDate, () => {
        return searchDate;
      });
  getSchedDate()
}, [searchLender, searchAmount, searchDate]);

  function loadingData() {
    axios
      .get(baseURL + `/partner-commissions/99999/1/search`, {
        params: {
          scheduled_date: searchDate !== "" ? searchDate : undefined,
          commission_expected: searchAmount !== "" ? searchAmount : undefined,
          lender_name: searchLender !== "" ? searchLender : undefined,
        },
        headers: headers,
      })
      .then((response) => {
        setComm(response.data.data, () => {
          return comm;
        });
        setTimeout(setLoading(false), 2000);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const lenderList = [];
  const lenderArray = [];
  function passLenderList() {
    const findLenders = comm?.data.forEach((comm) => {
      if (lenderList.includes(comm.lender_name)) {
        return;
      } else {
        lenderList.push(comm.lender_name);
      }
      return lenderList;
    });
    const lenderLabel = comm?.data?.forEach((lender) => {
      if (lenderArray.includes(lender.lender_name)) {
        return;
      } else {
        lenderArray.push({
          label: lender.lender_name,
          value: lender.lender_name,
        });
      }
      return;
    });
    return lenderList;
  }

  useEffect(() => {
    passLenderList();
  }, [passLenderList]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true, console.log("loading is set"));
    setTimeout(loadingData(), 1000);
  }, []);

  const index = props.index;
  const tableWidth = {
    checkbox: {
      width: "5%",
      justifyContent: "space-around",
    },
    created: {
      width: "5%",
      justifyContent: "space-around",
    },
    partner: {
      width: "5%",
      justifyContent: "space-around",
    },
    

    // this is just for heding....
    partner_info: {
      width: "15%",
      justifyContent: "space-around",
    },
    
    partner_info_span: {
      fontSize: "10px",
      width: "15%",
      justifyContent: "space-around",
    },
    lead: {
      width: "5%",
      justifyContent: "space-around",
    },
    sale: {
      width: "5%",
      justifyContent: "space-around",
    },
    comm: {
      width: "5%",
      justifyContent: "space-around",
    },
    date: {
      width: "5%",
      justifyContent: "space-around",
    },
    lender: {
      width: "10%",
      justifyContent: "space-around",
    },
    lenderBankRef:{
      width: "10%",
      justifyContent: "center",
    },
    invoiceNo:{
      width: "10%",
      justifyContent: "center",
    },


  };
  const fundedWidth = {
    amount: {
      width: "5%",
      justifyContent: "space-around",
    },
    dealType: {
      width: "5%",
      justifyContent: "space-around",
    },
    date: {
      width: "7.5%",
      justifyContent: "space-around",
    },
  };
  const commWidth = {
    broker: {
      width: "5%",
      justifyContent: "space-around",
    },
    expected: {
      width: "5%",
      justifyContent: "space-around",
    },
    scheduled: {
      width: "5%",
      justifyContent: "space-around",
    },
    status: {
      width: "5%",
      justifyContent: "space-around",
    },
    note:{
      width: "10%",
      justifyContent: "space-around",
    }
  };
  const modalStyles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.28)",
    },
    content: {
      display: "flex",
      width: "1200px",
      height: "725px",
      flexDirection: "column",
      inset: "10%",
      justifyContent: "flex-start",
      margin: "0 auto",
    },

  }


  const modalNotesStyle = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.28)",
    },
    content: {
      display: "flex",
      width: "450px",
      height: "220px",
      flexDirection: "column",
      inset: "10%",
      justifyContent: "flex-start",
      margin: "0 auto",
    },

  }



  const tableContentMinddle={
      thead: {
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center"
      },
      tbody:{
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center"
      },
      tbodyRow:{
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center"
      }

  };

  const filteredLenderLower = props.filterLender.toLowerCase();
  const filteredDateLower = props.filterDate.toLowerCase();
  const filteredAmountLower = props.filterAmount.toLowerCase();

  const options = [];

  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalNoteOnly,setModalNote] = useState(false);
  const [modalCommNoteOnly,setCommModalNote] = useState(false);
  const [commision_id_for_note,setCurrnetCommisionID] = useState(0);
  const [commision_notes_values,setCommisionNotesValues] = useState("");

  function openModal() {
    setIsOpen(true);
  }

  function openNoteModal() {
    setModalNote(true);
  }
  function openModelComm(commision_id,commision_note){

    setCommModalNote(true);
    setCurrnetCommisionID(commision_id);
    setCommisionNotesValues(commision_note);
  }



  function closeModal() {
    setIsOpen(false);
    setIsOther(false);
    setIsPartial(false);
  }


  function closeNoteModal(){
    setModalNote(false);
  }

  function closeCommNoteModal(){
    setCommModalNote(false);
  }

  function onInvoiceClick (invoiceNo){
      alert(invoiceNo);
      setInvoiceEdit(true);
      setinvoiceNo(invoiceNo);
    
  }


  const [checked, setChecked] = useState(new Array(options.length).fill(false));
  const [total, setTotal] = useState(0);
  const [passData, setPassData] = useState("");
  const [isPartial, setIsPartial] = useState(false);
  const [isOther, setIsOther] = useState(false);
  const [invoiceNo, setinvoiceNo] = useState(0);
  const [lenderPayRef, setPyaRef] = useState(0);

  const [invoiceEdit, setInvoiceEdit] = useState(false);

  const handleChangeInvoiceNo = ({ target }) => {
    setinvoiceNo(target.value);
  };

  const handleChangeLenderPayRef = ({ target }) => {
    setPyaRef(target.value);
  };

  function saveInvoice(commision_id){
    // get the input value
    //setInvoiceEdit(false);
    axios
    .post(
      baseURL + `/edit-invoiceno/${commision_id}`, {
        invoice_no: invoiceNo,
      },{
        headers:headers
      }
    ).then(res => {
      console.log(res.data);
      if(res.data.success){
        alert("saved!");
      }
    }
    ).catch(err => {
      console.log(err);
    });
  }

  function savePayRef(commision_id){
    // get the input value
    //setInvoiceEdit(false);
    axios
    .post(
      baseURL + `/edit-lender-payref/${commision_id}`, {
        lender_pay_ref: lenderPayRef,
      },{
        headers:headers
      }
    ).then(res => {
      console.log(res.data);
      if(res.data.success){
        alert("saved!");
      }
    }
    ).catch(err => {
      console.log(err);
    });
  }




  if (!comm) return null;

    const splits = [];
    const splitArray = checked.forEach((comm) => {
      splits.push(comm.commission_id.toString());
    });

  const parentPassData = (checked) => {
    setPassData(checked);
    openModal();
  };

  comm?.data.sort(sortDate);

  function sortDate(a, b) {
    return new Date(b.scheduled_date).valueOf() - new Date(a.scheduled_date).valueOf();
  }

  searchData?.data.sort(sortDate);


  const handleOnChange = (position) => {
    const tx = !searchData ? comm.data[position] : searchData.data[position];
    let newCommm = checked;
    if (
      checked.some(
        (commission) => commission.commission_id === tx.commission_id
      )
    ) {
      newCommm.splice(newCommm.indexOf(tx), 1);
      setChecked(newCommm);
    } else {
      newCommm.push(tx);
      setChecked(newCommm);
    }

    let totalPrice = 0;
    newCommm.forEach((commission) => {
      console.log("commision_expted before ===> "+commission.commission_expected);
      totalPrice += parseFloat(commission.commission_expected);
      console.log("commision_expted ===> "+commission.commission_expected);

    });

    console.log("total ===> "+totalPrice);
  

    setTotal(totalPrice);
  };

  const totalRemaining = () => {
    let amount = props.data.data[index].amount;
    let remaining = total.toFixed(2);
    return amount - remaining;
  };



  function setPartial(id) {
    openModal();
    axios
      .post(
        baseURL + `/set-partial/${id}`, {
          split: splits,
        },
        {
          headers: headers
        },
      )
      .then((response) => {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
    setIsPartial(true);
  }

  function setOther(id) {
    axios
      .post(baseURL + `/set-other/${id}`, {
      },{
        headers: headers
      })
      .then((response) => {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function setOtherModal(id) {
    openModal();
    axios
      .post(baseURL + `/set-other/${id}`, {
      },{
        headers: headers
      })
      .then((response) => {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
    setIsOther(true);
  }

  if (!comm) return null;

  if (loading || !comm.data) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "auto",
          height: "100%",
          width: "100%",
        }}
      >
        <img src={Spinner} style={{ width: "4rem", height: "auto" }} />
      </div>
    );
  }

  // const findDetails = comm.data.findIndex((tx) => tx.partner_id == props.commData.data.parnter_id)
  // console.log(findDetails)
  const unrecoList = (!searchData ? comm.data : searchData.data)
    .map((item, index) => (
      <TransRow key={index} style={tableContentMinddle.thead}>
        <TransTd style={tableWidth.checkbox}>
          <CheckContainer>
            <TransCheckbox
              onChange={() => handleOnChange(index)}
              checked={checked.some(
                (commission) => commission.commission_id === item.commission_id
              )}
            />
          </CheckContainer>
        </TransTd>
        <TransTd style={tableWidth.created}>
          {dayjs(item.created).format("DD/MM/YYYY")}
        </TransTd>
        <TransTd style={tableWidth.partner}>{`${item.partner_id}`}</TransTd>
        <TransTd style={tableWidth.partner_info_span}>
     
            {`${(item.lead_owner_email!='')?item.lead_owner_email:''}`} <br />
            {`${(item.lead_owner_first_name !=''?item.lead_owner_first_name+" ":'')}`}
            {`${(item.lead_owner_last_name !=''?item.lead_owner_last_name:'')}`} <br />
            {`${(item.organisation_name !=''?item.organisation_name:'')}`}
        
        </TransTd>
        <TransTd style={tableWidth.lead}>{item.lead_ref}</TransTd>
        <TransTd style={tableWidth.sale}>{item.sale_ref}</TransTd>
        <TransTd style={tableWidth.lenderBankRef}>
              <input type='text'  name="lender_payref_number" 
                          id={'lender_payref_no' +item.commission_id}    
                          onChange={handleChangeLenderPayRef}
                          style={{width: '75px'}}
                          defaultValue={(lenderPayRef ==0)? item.lender_pay_ref:lenderPayRef} />
                  <button className="btn btn-primary" onClick={()=>savePayRef(item.commission_id)}>Save</button>
        </TransTd>
        
                
        {/* {invoiceEdit==false ? (
        <TransTd style={tableWidth.invoiceNo} onClick={()=>onInvoiceClick(item.commission_id)}>{item.invoice_no}</TransTd>
        ) : ( 
          <TransTd style={tableWidth.invoiceNo}>
            <input type='text' value={item.invoice_no}/>
          </TransTd>
        )} */}
        

        <TransTd style={tableWidth.invoiceNo}>
            <input type='text'  name="invioice-number" 
                    id={'invoice_no' +item.commission_id}    
                    onChange={handleChangeInvoiceNo}
                    style={{width: '75px'}}
                    defaultValue={(invoiceNo ==0)? item.invoice_no:invoiceNo} />
             <button className="btn btn-primary" onClick={()=>saveInvoice(item.commission_id)}>Save</button>
        </TransTd>

        <TransTd style={fundedWidth.amount}>{item.funded_amount}</TransTd>
        <TransTd style={fundedWidth.dealType}>{item.funded_type}</TransTd>
        <TransTd style={fundedWidth.date}>
          {dayjs(item.funded_date).format("DD/MM/YYYY")}
        </TransTd>
        <TransTd style={commWidth.broker}>{item.lender_name}</TransTd>
        <TransTd style={commWidth.expected}>{item.commission_expected}</TransTd>
        <TransTd style={commWidth.scheduled}>
          {dayjs(item.scheduled_date).format("DD/MM/YYYY")}
        </TransTd>
        <TransTd style={commWidth.status}>{item.status}</TransTd>
        <TransTd style={commWidth.note}>
          <button onClick={()=>openModelComm(item.commission_id,item.note)}> View</button>
        </TransTd>
      </TransRow>
    ));
  return (
    <>



      <Modal
              isOpen={modalCommNoteOnly}
              onRequestClose={closeNoteModal}
              style={modalNotesStyle}
            >
              <CloseButton onClick={closeCommNoteModal}>&#10006;</CloseButton>
              <CommisionNotes
                checked={checked}
                close={closeCommNoteModal}
                comid={commision_id_for_note}
                comnote={commision_notes_values}
              />
            </Modal>




      <Modal
        isOpen={modalNoteOnly}
        onRequestClose={closeNoteModal}
        style={modalNotesStyle}
      >
        <CloseButton onClick={closeNoteModal}>&#10006;</CloseButton>
        <TxNotes
          checked={checked}
          close={closeNoteModal}
          id={props.data.data[index].tx_id}
        />
      </Modal>



      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={modalStyles}
      >
        <CloseButton onClick={closeModal}>&#10006;</CloseButton>
        <ModalHeaderWrapper>
          <PageHeader
            header="Confirmation"
            blurb={`Are you sure you wish to reconcile transaction ${props.id}:`}
          />
          <ModalRow>
            <ModalTd>
              {dayjs(props.data.data[index].date).format("DD/MM/YYYY")}
            </ModalTd>
            <ModalTd>{props.data.data[index].text}</ModalTd>
            <ModalTd>{props.data.data[index].amount}</ModalTd>
          </ModalRow>
        </ModalHeaderWrapper>
        <ModalBlurbWrapper>
          <PageHeader blurb="With the following payments:" />
        </ModalBlurbWrapper>
        <ModalTableUnrecoConfirm
          checked={checked}
          close={closeModal}
          id={props.data.data[index].tx_id}
        />
      </Modal>
      <TableWrapper className="scrollableShadows" style={{overflow:'scroll'}}>
        <Table>
          <TopHeaderRow>
            <TopTableHeader>Funded Details</TopTableHeader>
            <TopTableHeader>Commission Details</TopTableHeader>
          </TopHeaderRow>
          <TableHead style={tableContentMinddle.thead}>
            <TableHeader style={tableWidth.checkbox}></TableHeader>
            <TableHeader style={tableWidth.created}>Created</TableHeader>
            <TableHeader style={tableWidth.partner}>Partner ID</TableHeader>
            <TableHeader style={tableWidth.partner_info}>Lead Owner info</TableHeader>
            <TableHeader style={tableWidth.lead}>Lead Ref</TableHeader>
            <TableHeader style={tableWidth.sale}>Sale Ref</TableHeader>
            <TableHeader style={tableWidth.lenderBankRef}>Lender Bank Ref</TableHeader>
            <TableHeader style={tableWidth.invoiceNo}>Invoice No</TableHeader>
            <TableHeader style={fundedWidth.amount}>Amount</TableHeader>
            <TableHeader style={fundedWidth.dealType}>Deal Type</TableHeader>
            <TableHeader style={fundedWidth.date}>When</TableHeader>
            <TableHeader style={commWidth.broker}>Lender</TableHeader>
            <TableHeader style={commWidth.expected}>Expected</TableHeader>
            <TableHeader style={commWidth.scheduled}>Scheduled</TableHeader>
            <TableHeader style={commWidth.status}>Status</TableHeader>
            <TableHeader style={commWidth.note}>Note</TableHeader>
          </TableHead>
          <TableBody>
              {(loading ==true)?<SpinnerUtit display={'flex'} loading={loading} />:
                 (unrecoList.length) > 0 ? unrecoList : <NoData />
              }
             
          </TableBody>
        </Table>
      </TableWrapper>
      <ConfirmWrapper>
        <ConfirmHeaderWrapper>
          <ConfirmHeader>Total: </ConfirmHeader>
          <ConfirmTotal>
            {total.toFixed(2)  == props.data.data[index].amount ? (
              <ConfirmGreen>${total.toFixed(2)}</ConfirmGreen>
            ) : (
              <>${total.toFixed(2)}</>
            )}
          </ConfirmTotal>
        </ConfirmHeaderWrapper>
        <ConfirmBlurb>
          {(total.toFixed(2) == props.data.data[index].amount && total !=0) ? (
            <ConfirmGreen>
              The selected payments equals the same figure as the bank
              transaction amount (${props.data.data[index].amount}), you are now
              able to reconcile this transaction.
            </ConfirmGreen>
          ) : (
            <>
              The total expected commission amount for the selected payments
              does not equal to the bank transaction amount ($
              {props.data.data[index].amount})... a further{" "}
              <b>${totalRemaining().toFixed(2)}</b> is outstanding.
            </>
          )}
        </ConfirmBlurb>
        <ModalButtonWrapper>
          {(total.toFixed(2) == props.data.data[index].amount && total !=0) ? (
            <ConfirmButton onClick={() => parentPassData()}>
              Set Full
            </ConfirmButton>
          ) : (
            ""
          )}
          {checked.length >= 1 ? (
            <ConfirmButton
              other
              onClick={() => setPartial(props.data.data[index].tx_id)}
            >
              Set Partial
            </ConfirmButton>
          ) : (
            ""
          )}
          <ConfirmButton
            other
            onClick={() => setOtherModal(props.data.data[index].tx_id)}
          >
            Set Other
          </ConfirmButton>
          <ConfirmButton other onClick={() => openNoteModal()}>
            Set Note
          </ConfirmButton>

      


          
        </ModalButtonWrapper>
        <ModalButtonWrapper style={{ margin: "0 0 0 4rem" }}>
          {isOther ? (
            <ConfirmWrapper style={{ color: "#67BE23", fontWeight: "500" }}>
              Transaction {props.data.data[index].tx_id} was set to Other ✔
            </ConfirmWrapper>
          ) : null}
          {isPartial ? (
            <ConfirmWrapper style={{ color: "#67BE23", fontWeight: "500" }}>
              Transaction {props.data.data[index].tx_id} was set to Partial ✔
            </ConfirmWrapper>
          ) : null}
        </ModalButtonWrapper>
      </ConfirmWrapper>

      <div style={{width:"80%",marginTop:"50px",
                  marginBottom:"20px",
                  border: "1px solid grey",
                  padding: "10px"}}>
        <strong>Full match</strong> - bank transaction amount and commission/s selected are an exact 100% match.
        <br/>
        <strong>Partial</strong> - bank transaction amount is a mixture made up of commission/s as well as something else such as VBI, promotions, 
        trail (include a note of what and how much).
        <br/>
        <strong>Other</strong> - bank transaction is NOT commission, and is VBI, promotional payment, 
        trail, Phil moving around money, etc. Include a note.
      </div>

    </>
  );
});

export default ModalTableUnreco;
