 // create a functional component
import React, { useState, useEffect } from "react";
import Select from "react-select";
import { HeaderWrapper } from "../Components/Core/Core.styles";
import PageHeader from "../Components/PageHeader/PageHeader";
import TransactionTableRecHistory from "../Components/TransactionReconHistory/TransactionTableRecHistory";
import {
  PaginationWrapper,
  PaginationHeader,
  PageDropdown,
} from "../Components/Pagination/Pagination.styles";
import Spinner from "../Assets/SpinBrokerSpin.gif";

// functionaal component with state

const baseURL = process.env.REACT_APP_API_URL;
const axios = require("axios").default;

const ReconHistory = () => {

    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
  
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(99999);
    const token = window.localStorage.getItem("token");
    const [message, setMessage] = useState("");

    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const basePage = 1;

    useEffect(() => {
        setLoading(true);
        axios
          .get(baseURL + `/get-reconcilied-payments/${limit}/${page}`, {
            headers: headers
          })
          .then((response) => {
            setData(response.data);
          })
          .catch(function (error) {
            console.log(error);
          })
          .finally(() => {
            setLoading(false);
          });
      }, [limit, page, token]);

    if (!data && loading) {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "0 auto",
            }}
          >
            <img src={Spinner} style={{ width: "4rem", height: "auto" }} />
          </div>
        );
    }

    if(!data) return null;

    return (
        <div>
          <HeaderWrapper>
            <PageHeader
              header="View Reconciliation History"
              blurb="View all reconsilation transacation history and Rollback if needed."
            />
          </HeaderWrapper>
          <PaginationWrapper style={{ width: "350px", margin: "0rem" }}>
    
          </PaginationWrapper>
          <TransactionTableRecHistory data={data} page={page} limit={limit}></TransactionTableRecHistory>

        </div>
    );

};

export default ReconHistory;






