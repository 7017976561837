import { useState, useEffect } from "react";
import {
  TableWrapper,
  Table,
  TableBody,
  TableHead,
  HeaderRow,
  TableHeader,
  TransRow,
  TransTd,
  TableButton,
} from "./TransactionTable.styles";
import Modal from "react-modal";
import { CloseButton } from "../Modal/Modal.styles.js";
import ModalTable from "../ModalTable/ModalTable";
import {
  ModalHeaderWrapper,
  ModalBlurbWrapper,
  ModalRow,
  ModalTd,
  NotesWrapper,
  NotesBlurb,
} from "../ModalTable/ModalTable.styles";
import PageHeader from "../PageHeader/PageHeader";
const dayjs = require("dayjs");
const axios = require("axios").default;

const TransactionTable = (props) => {
  const limit = props.limit;
  const page = props.page;
  const data = props.data;
  const filterData = props.filterData;
  const [modalIsOpen, setIsOpen] = useState(false);
  const [txId, setTxId] = useState(0);
  const [txIndex, setTxIndex] = useState(0);

  if (!data) return null;

  const widthStyles = {
    id: {
      width: "5%",
    },
    date: {
      width: "15%",
    },
    desc: {
      width: "50%",
    },
    amount: {
      width: "15%",
    },
    action: {
      width: "15%",
    },
  };

  const modalStyles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.28)",
    },
    content: {
      display: "flex",
      width: "1200px",
      height: "600px",
      flexDirection: "column",
      inset: "10%",
      justifyContent: "flex-start",
      margin: "0 auto",
    },
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const confirmPassData = (tx_id, index) => {
    setTxIndex(index);
    setTxId(tx_id);
    openModal();
  };

  // getting notes and displaying notes not working just yet


  const filterList = filterData.map((item, index) => (
    <TransRow>
      <TransTd style={widthStyles.id} key={index.toString()}>
        {item.tx_id}
      </TransTd>
      <TransTd style={widthStyles.date}>
        {dayjs(item.date).format("DD/MM/YYYY")}
      </TransTd>
      <TransTd style={widthStyles.desc}>{item.text}</TransTd>
      <TransTd style={widthStyles.amount}>{item.amount}</TransTd>
      <TableButton onClick={() => confirmPassData(item, index)}>
        View Recon Details
      </TableButton>
    </TransRow>
  ));

  return (
    <>
      <TableWrapper>
        <Table>
          <TableHead>
            <HeaderRow>
              <TableHeader style={widthStyles.id}>ID</TableHeader>
              <TableHeader style={widthStyles.date}>
                Transaction Date
              </TableHeader>
              <TableHeader style={widthStyles.desc}>Description</TableHeader>
              <TableHeader style={widthStyles.amount}>Amount</TableHeader>
              <TableHeader style={widthStyles.action}>Actions</TableHeader>
            </HeaderRow>
          </TableHead>
          <TableBody>{filterList}</TableBody>
        </Table>
      </TableWrapper>
      {!data || filterData[txIndex] == undefined ? null : (
        <>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={modalStyles}
          >
            <CloseButton onClick={closeModal}>&#10006;</CloseButton>
            <ModalHeaderWrapper>
              <PageHeader
                header="Auto-Recon Confirmation"
                blurb={`Are you sure that transaction: ${filterData[txIndex].tx_id} ${props.notes}`}
              />
              <ModalRow>
                <ModalTd>
                  {dayjs(filterData[txIndex].date).format("DD/MM/YYYY")}
                </ModalTd>
                <ModalTd>{filterData[txIndex].text}</ModalTd>
                <ModalTd>{filterData[txIndex].amount}</ModalTd>
              </ModalRow>
            </ModalHeaderWrapper>
            <ModalBlurbWrapper>
              <PageHeader blurb="has been correctly auto-reconciliated with the following payment(s):" />
            </ModalBlurbWrapper>
            <ModalTable
              data={filterData}
              id={txId}
              index={txIndex}
              close={closeModal}
            />
          </Modal>
        </>
      )}
    </>
  );
};

export default TransactionTable;
