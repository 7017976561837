import { useState, useEffect, useRef } from "react";
import {
  HeaderWrapper,
  AltHeaderWrapper,
} from "../Components/Core/Core.styles";
import PageHeader from "../Components/PageHeader/PageHeader";
import { DescFilter } from "../Components/Pagination/Pagination.styles";
import {
  PaginationWrapper,
  PaginationHeader,
  PageDropdown,
} from "../Components/Pagination/Pagination.styles";
import {
  UnrecoModalWrapper,
  RecoModalWrapper,
  ModalRow,
  ModalTd,
  TableButton,
} from "../Components/ModalTable/ModalTable.styles";
import ModalTableUnreco from "../Components/ModalTableUnreco/ModalTableUnreco";
import ModalTableReco from "../Components/ModalTableReco/ModalTableReco";
import {
  TableHead,
  TableHeader,
  HeaderRow,
  ConfirmButton,
} from "../Components/ModalTableUnreco/ModalTableUnreco.styles";
import Spinner from "../Assets/SpinBrokerSpin.gif";
import Select from "react-select";
import "../index.css";
import { prototype } from "react-modal";
import NoData from "../Components/Utility/NoData";

const baseURL = process.env.REACT_APP_API_URL;
const dayjs = require("dayjs");
const axios = require("axios").default;

const UnrecoTrans = (props) => {
  const unrecoData = props.data;
  const [data, setData] = useState(props.data);
  const [searchData, setSearchData] = useState();
  const [commData, setCommData] = useState();
  const [loading, setLoading] = useState(false);
  const [filterLender, setFilterLender] = useState("");
  const [filterDate, setFilterDate] = useState("");
  const [filterAmount, setFilterAmount] = useState("");
  const token = window.localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [lenderData, setLenderData] = useState("");

  const [lender, setLender] = useState("Filter by Lender");
  const lenderList = [];

  const [limit, setLimit] = useState(99999);

  function getComms() {
    axios
      .get(baseURL + `/partner-commissions/99999/1/search`, {
        headers: headers
      })
      .then((response) => {
        setCommData(response.data, () => {
          return commData;
        });
        console.log(commData);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  // FILTER LENDER DROP TO ONLY ONE LENDER
  commData?.data?.data?.forEach((comm) => {
    let flag = true;
    for (let i = 0; i < lenderList.length; i++) {
      if (lenderList[i].value === comm.lender_name) {
        flag = false;
        break;
      }
    }
    if (flag)
      lenderList.push({ label: comm.lender_name, value: comm.lender_name });
  });


  useEffect(() => {
    
    console.log(props);

    getComms();
  }, []);

  useEffect(() => {
    setLoading(true);
    function getUnreco() {
      axios
        .get(baseURL + `/get-reconcilied-payments/${limit}/1`, {
          headers: headers
        })
        .then((response) => {
          setData(response.data);
        });
    }
    getUnreco();
    setLoading(false);
  }, [limit, token]);

  const handleLender = (e) => {
    const value = e.value;
    setLender(value);
    setFilterLender(value);
    setSearchLender(value);
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      childRef.current.getSchedDate();
    } else {
      return;
    }
  };

  const [searchLender, setSearchLender] = useState(filterLender);
  const [searchDate, setSearchDate] = useState(filterDate);
  const [searchAmount, setSearchAmount] = useState(filterAmount);

  const childRef = useRef();
  
  const resetParams = () => {
    setLender("");
    setFilterLender("");
    setSearchLender("");
    setFilterAmount("");
    setSearchAmount("");
    setFilterDate("");
    setSearchDate("");
  };

  function resetFilter() {
    resetParams();
    childRef.current.getSchedDate();
    getComms();
  }

  function handleRollback(txt_id){
    let message = "Are you sure you want to rollback this transaction?";
    if (window.confirm(message) === true) {

      axios
        .post(
          baseURL + `/rollback-reconn-tx-other/${txt_id}`,
          {
            is_partial: true, 
          },
          {
            headers: headers,
          }
        )
        .then((res) => {
          console.log(res);
          alert(
            "Rolledback Successfull. Page will refresh"
          );
          //window.location.reload();
          window.location.href = "/";
        })
        .catch((err) => {
          console.log(err);
          alert("Rollback Error");
        });
    } 
  }

  if (!data) return null;

  const headerWidth = {
    header: {
      width: "100%",
    },
    id: {
      width: "10%",
    },
    date: {
      width: "15%",
    },
    desc: {
      width: "40%",
    },
    amount: {
      width: "25%",
    },
    rectype:{
      width: "25%",
    },
    action: {
      width: "10%",
    },
  };

  if (loading || !data) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "0 auto",
        }}
      >
        <img src={Spinner} style={{ width: "4rem", height: "auto" }} />
      </div>
    );
  }

  const index = data.data.findIndex((tx) => tx.tx_id == props.id);

  if (index < 0){
    return <NoData />;
  }

  return (
    <div>
      <AltHeaderWrapper>
        <PageHeader
          header={`Reconcilised Transaction ID ${props.id}`}
          blurb="Any bank transactions we were unable to automatically reconcile must be done manually. "
        />
      </AltHeaderWrapper>
      <RecoModalWrapper>
        <TableHead>
          <HeaderRow style={headerWidth.header}>
            <TableHeader style={headerWidth.id}>ID</TableHeader>
            <TableHeader style={headerWidth.date}>Transaction Date</TableHeader>
            <TableHeader style={headerWidth.date}>Reconn Date</TableHeader>
            <TableHeader style={headerWidth.desc}>Description</TableHeader>
            <TableHeader style={headerWidth.amount}>Amount</TableHeader>
            <TableHeader style={headerWidth.rectype}>Recon Type</TableHeader>
            <TableHeader style={headerWidth.action}> Full Rollback </TableHeader>
          </HeaderRow>
        </TableHead>
        <ModalRow>

          <ModalTd style={headerWidth.id}>{props.id}
              {(data.data[index].partial==1) ? '  {Partial}' : null}
          </ModalTd>

          <ModalTd style={headerWidth.date}>
            {dayjs(data.data[index].date).format("DD/MM/YYYY")}
          </ModalTd>

          <ModalTd style={headerWidth.date}>
            {(data.data[index].recon_date) ? dayjs(data.data[index].recon_date).format("DD/MM/YYYY") : '-'}
            {/*dayjs(data.data[index].date).format("DD/MM/YYYY")*/}
          </ModalTd>
          
          <ModalTd style={headerWidth.desc}>{data.data[index].text}</ModalTd>
          
          <ModalTd style={headerWidth.amount}>
            {data.data[index].amount}
          </ModalTd>

          <ModalTd style={headerWidth.rectype}>
              {(data.data[index].partial===1) ? 'Partial' : 'Full'}
              {(data.data[index].other===1) ? ' [Other] ' : ''}
          </ModalTd>

          <ModalTd style={headerWidth.action}> 
            {data.data[index].other===1 ? <button className="blueButton" 
                                            onClick={() => handleRollback(data.data[index].tx_id)}>
                                            Rollback Fully</button> : 'This is not an [other] Transaction'}
          </ModalTd>

        </ModalRow>
        
      </RecoModalWrapper>


      <HeaderWrapper>
        <PageHeader header="Payments" />
      </HeaderWrapper>


      <RecoModalWrapper>
        <ModalTableReco
          ref={childRef}
          filterLender={filterLender}
          filterAmount={filterAmount}
          filterDate={filterDate}
          data={data}
          commData={commData}
          searchData={searchData}
          id={props.id}
          commisionID ={data.data[index].commission_id}
          partial={(data.data[index].partial==1) ? true : false}
          index={index}
          searchLender={searchLender}
          searchDate={searchDate}
          searchAmount={searchAmount}
        />
      </RecoModalWrapper>
    


    </div>
  );
};

export default UnrecoTrans;
