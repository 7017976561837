import styled from "styled-components";

export const MainSection = styled.div`
  /* width: 100%; */
  display: flex;
  flex-wrap: wrap;
  margin: 2rem 4rem;
  justify-content: flex-start;
  align-items: center;
  vertical-align: middle;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin: 2rem 2rem 0;
  }
`;

export const HeaderWrapper = styled.div`
  /* width: 100%; */
  display: flex;
  margin: 4rem 4rem 0;
  justify-content: left;
  align-items: center;
  vertical-align: middle;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin: 2rem 4rem 0;
    text-align: center;
  }
`;

export const AltHeaderWrapper = styled.div`
  /* width: 100%; */
  display: flex;
  margin: 2rem 4rem 0;
  justify-content: left;
  align-items: center;
  vertical-align: middle;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin: 2rem 4rem 0;
    text-align: center;
  }
`;

export const Footer = styled.footer`
  width: 100%;
  height: 6rem;
  background-color: #DEDEDE;
`

export const FooterDiv = styled.div``