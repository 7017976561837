import React from 'react'
import Nav from "../../Components/Navbar/Navbar"
import { Container, LendLink, LendLogo } from "../Login/Login.styles"
const Login = () => {
  return (
    <Container>
      <LendLogo src="https://www.lend.com.au/design-system/image/logos/lend/logo-lend.svg" />
      <h1>You do not have access to view this page.</h1>
      <h2>
        Please log in to{" "}
        <LendLink href="https://team.lend.com.au/" target="_blank">
          Team Lend
        </LendLink>{" "}
        to gain access.
      </h2>
    </Container>
  );
}

export default Login
