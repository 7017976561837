import { useState, useEffect } from "react";
import Select from "react-select";
import { HeaderWrapper } from "../Components/Core/Core.styles";
import PageHeader from "../Components/PageHeader/PageHeader";
import TransactionTableUnre from "../Components/TransactionTableUnre/TransactionTableUnre";
import {
  PaginationWrapper,
  PaginationHeader,
  PageDropdown,
} from "../Components/Pagination/Pagination.styles";
import Spinner from "../Assets/SpinBrokerSpin.gif";
const baseURL = process.env.REACT_APP_API_URL;
const axios = require("axios").default;
const UnrecoTrans = () => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(99999);
  const token = window.localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const basePage = 1;

  useEffect(() => {
    setLoading(true);
    axios
      .get(baseURL + `/get-unreconcilied-payments/${limit}/${page}`, {
        headers: headers
      })
      .then((response) => {
        setData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [limit, page, token]);

  if (!data && loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "0 auto",
        }}
      >
        <img src={Spinner} style={{ width: "4rem", height: "auto" }} />
      </div>
    );
  }

  if(!data) return null;


  // PAGINATION CODE IN CASE WE NEED THAT LATER
  //   const totalPages = Math.ceil(
  //     data.pagination.total / data.pagination.perPage
  //   );

  //   const maxTx = data.pagination.total;

  //   console.log(
  //     "you need",
  //     totalPages,
  //     "for",
  //     data.pagination.total,
  //     "results if you show",
  //     data.pagination.perPage,
  //     "at a time"
  //   );

  // const firstPage = 1;
  // const multiPages = [];
  // // additional pages based on total pages requried
  // const addPages = Array.from({ length: totalPages - firstPage }, (_, i) => i + firstPage);

  // // initial page
  // const initPage = Array.from([totalPages - firstPage],
  //    (i) => i + firstPage
  // );
  // console.log(initPage)
  
  // addPages.forEach((page) => {
  //   multiPages.push({ label: page, value: page });
  // });
  // initPage.forEach((page) => {
  //   multiPages.push({label: page, value: page });
  // });
  // console.log(multiPages[multiPages.length-1])
  // console.log(multiPages);

  return (
    <div>
      <HeaderWrapper>
        <PageHeader
          header="Un-Reconciled Transactions"
          blurb="Any bank transactions we were unable to automatically reconcile must be done manually. "
        />
      </HeaderWrapper>
      <PaginationWrapper style={{ width: "350px", margin: "0rem" }}>

      </PaginationWrapper>
      <TransactionTableUnre data={data} page={page} limit={limit}></TransactionTableUnre>
    </div>
  );
};

export default UnrecoTrans;
