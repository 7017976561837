import { Header, HeaderBlurb } from "./PageHeader.styles"
import React from 'react'

const PageHeader = (props) => {
  return (
    <div>
      <Header>
        {props.header}
      </Header>
      <HeaderBlurb>
        {props.blurb}
      </HeaderBlurb>
    </div>
  )
}

export default PageHeader
