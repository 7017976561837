import React from 'react'
import Cards from "../Components/Cards/Cards";
import PageHeader from "../Components/PageHeader/PageHeader";
import { MainSection, HeaderWrapper } from "../Components/Core/Core.styles";
import { BrowserRouter as Router, Route } from "react-router-dom";

const Home = () => {
  return (
    <>
      <HeaderWrapper>
        <PageHeader header="Reconciliation" blurb="" />
      </HeaderWrapper>
      <MainSection>
        <Cards
          header="Confirm Auto-Reconciliations"
          blurb="Bank transactions that have been auto-reconciled by this service need to be confirmed as a correct match."
          link="confirm"
        />
        <Cards
          header="Un-Reconciled Transactions"
          blurb="Any bank transactions we were unable to automatically reconcile must be done manually."
          link="unrecotransactions"
        />
      </MainSection>
      <HeaderWrapper>
        <PageHeader header="Payments & Transactions" blurb="" />
      </HeaderWrapper>
      <MainSection>

        <Cards
          header="View All Bank Transactions"
          blurb="View all bank transactions we have in our database. You can search and filter these transactions to help you."
          link="transactions"
        />
        <Cards
            header="View Reconciliation History"
            blurb="View all reconsilation transacation history and Rollback if needed."
            link="reconshistory"
        />
      </MainSection>

    </>
  );
}

export default Home
