import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";

export const CardDiv = styled.div`
  width: 300px;
  height: 180px;
  background-color: white;
  border: 1px solid #d6d6d6;
  box-shadow: 0px 4px 10px 0px #00000012;
  border-radius: 14px;
  padding: 1rem 2rem;
  margin: 2rem 2rem 0 0;

  @media screen and (max-width: 768px) {
    width: 280px;
    height: 150px;
    padding: 2rem 2rem;
    margin: 0 0 4rem 0;
  }

  @media screen and (max-width: 414px) {
    width: 220px;
    height: 180px;
  }
`;

export const CardHeader = styled.h1`
  padding-top: 1rem;
  font-size: 18px;
  font-weight: 700;
  color: #777777;

  @media screen and (max-width: 768px) {
    padding: 0rem;
  }
`;

export const CardP = styled.p`
  color: #9e9e9e;
  width: 260px;
  font-size: 14px;
  font-weight: 400;

  @media screen and (max-width: 414px) {
    width: 220px;
  }
`;

export const CardButton = styled.button`
  background-color: #1572de;
  color: white;
  width: 110px;
  height: 2rem;
  border-radius: 4px;
  outline: none;
  border: none;
  cursor: pointer;

  :active {
    border: 1px solid #d6d6d6;
  }
`;

export const ButtonLink = styled(LinkR) `
  color: white;
  width: 100%;
  padding: 5px 20px;
  text-decoration: none;
`