import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useCallback,
  useRef,
} from "react";
import Modal from "react-modal";
import PageHeader from "../PageHeader/PageHeader";
import SpinnerUtit from "../Utility/ProgressWheel";
import NoData from "../Utility/NoData";
import {
  TableWrapper,
  Table,
  TableHead,
  TopHeaderRow,
  TopTableHeader,
  TableHeader,
  TableBody,
  TransRow,
  TransTd,
  ModalButtonWrapper,
  ConfirmButton,
  TransCheckbox,
  ConfirmWrapper,
  ConfirmHeaderWrapper,
  ConfirmHeader,
  ConfirmTotal,
  ConfirmBlurb,
  CheckContainer,
  ConfirmGreen,
} from "./ModalTableReco.styles";
import {
  ModalHeaderWrapper,
  ModalBlurbWrapper,
  ModalRow,
  ModalTd,
} from "../ModalTable/ModalTable.styles";
import ModalTableRecoConfirm from "../ModalTableRecoConfirm/ModalTableRecoConfirm";
import { CloseButton } from "../Modal/Modal.styles.js";
import Spinner from "../../Assets/SpinBrokerSpin.gif";
const baseURL = process.env.REACT_APP_API_URL;
const dayjs = require("dayjs");
const axios = require("axios").default;

const ModalTableReco = forwardRef((props, ref, passLenderList) => {
  const [searchData, setSearchData] = useState();
  const [searchLender, setSearchLender] = useState(undefined);
  const [searchDate, setSearchDate] = useState(undefined);
  const [searchAmount, setSearchAmount] = useState(undefined);
  const [comm, setComm] = useState();
  const [commData, setCommData] = useState(props.commData);

  const token = window.localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  function loadingData(commision_id, txt_id) {
    //console.log("loading data" + props.data);

    axios
      .get(
        baseURL +
          `/get-reconcilied-partner-commissions/${commision_id}/${txt_id}`,
        {
          params: {},
          headers: headers,
        }
      )
      .then((response) => {
        //console.log("response--------" + response.data);
        setComm(response.data, () => {
          return JSON.parse(comm);
        });
        setTimeout(setLoading(false), 2000);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const lenderList = [];
  const lenderArray = [];
  function passLenderList() {
    const findLenders = comm?.data.forEach((comm) => {
      if (lenderList.includes(comm.lender_name)) {
        return;
      } else {
        lenderList.push(comm.lender_name);
      }
      return lenderList;
    });
    const lenderLabel = comm?.data?.forEach((lender) => {
      if (lenderArray.includes(lender.lender_name)) {
        return;
      } else {
        lenderArray.push({
          label: lender.lender_name,
          value: lender.lender_name,
        });
      }
      return;
    });
    return lenderList;
    //console.log(lenderList);
    //console.log(lenderArray);
  }

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true, console.log("loading is set"));
    //console.log(props.commisionID);

    setTimeout(loadingData(props.commisionID, props.id), 1000);
  }, []);

  const index = props.index;
  const tableWidth = {
    checkbox: {
      width: "10%",
      justifyContent: "space-around",
    },
    created: {
      width: "15%",
      justifyContent: "space-around",
    },
    partner: {
      width: "25%",
      justifyContent: "space-around",
    },
    lead: {
      width: "25%",
      justifyContent: "space-around",
    },
    sale: {
      width: "25%",
      justifyContent: "space-around",
    },
    comm: {
      width: "30%",
      justifyContent: "space-around",
    },
    date: {
      width: "10%",
      justifyContent: "space-around",
    },
    lender: {
      width: "30%",
      justifyContent: "space-around",
    },
  };
  const fundedWidth = {
    amount: {
      width: "25%",
      justifyContent: "space-around",
    },
    dealType: {
      width: "25%",
      justifyContent: "space-around",
    },
    date: {
      width: "50%",
      justifyContent: "space-around",
    },
  };
  const commWidth = {
    broker: {
      width: "25%",
      justifyContent: "space-around",
    },
    expected: {
      width: "25%",
      justifyContent: "space-around",
    },
    scheduled: {
      width: "25%",
      justifyContent: "space-around",
    },
    status: {
      width: "25%",
      justifyContent: "space-around",
    },
    actionRollback: {
      width: "25%",
      justifyContent: "space-around",
    },
  };
  const modalStyles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.28)",
    },
    content: {
      display: "flex",
      width: "1200px",
      height: "725px",
      flexDirection: "column",
      inset: "10%",
      justifyContent: "flex-start",
      margin: "0 auto",
    },
  };


  const tableContentMinddle={
    thead: {
      justifyContent: "center",
      alignItems: "center",
      alignContent: "center"
    },
    tbody:{
      justifyContent: "center",
      alignItems: "center",
      alignContent: "center"
    },
    tbodyRow:{
      justifyContent: "center",
      alignItems: "center",
      alignContent: "center"
    }

};

  const filteredLenderLower = props.filterLender.toLowerCase();
  const filteredDateLower = props.filterDate.toLowerCase();
  const filteredAmountLower = props.filterAmount.toLowerCase();

  const options = [];

  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    setIsOther(false);
    setIsPartial(false);
  }

  const [checked, setChecked] = useState(new Array(options.length).fill(false));
  const [total, setTotal] = useState(0);
  const [passData, setPassData] = useState("");
  const [isPartial, setIsPartial] = useState(false);
  const [isOther, setIsOther] = useState(false);
  if (!comm) return null;

  const splits = [];
  const splitArray = checked.forEach((comm) => {
    splits.push(comm.commission_id.toString());
  });

  const parentPassData = (checked) => {
    setPassData(checked);
    openModal();
  };

  comm?.data.sort(sortDate);

  function sortDate(a, b) {
    return (
      new Date(b.scheduled_date).valueOf() -
      new Date(a.scheduled_date).valueOf()
    );
  }

  searchData?.data.sort(sortDate);

  const handleOnChange = (position) => {
    const tx = !searchData ? comm.data[position] : searchData.data[position];
    let newCommm = checked;
    if (
      checked.some(
        (commission) => commission.commission_id === tx.commission_id
      )
    ) {
      newCommm.splice(newCommm.indexOf(tx), 1);
      setChecked(newCommm);
    } else {
      newCommm.push(tx);
      setChecked(newCommm);
    }
  };

  if (!comm) return null;

  if (loading || !comm.data) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "auto",
          height: "100%",
          width: "100%",
        }}
      >
        <img src={Spinner} style={{ width: "4rem", height: "auto" }} />
      </div>
    );
  }







  function rollbackTransaction(commision_id, tx_id) {

    const confirmRollback = window.confirm(
      "Are you sure you want to undo this reconciliation?"
    );
    if (confirmRollback) {
      const rollback_data = {
        r_txt_id: tx_id,
        r_commision_id: commision_id,
        r_partial:props.partial
      };

      axios
        .post(
          baseURL + `/rollback-reconn/${tx_id}/${commision_id}`,
          {
            is_partial: props.partial, 
          },
          {
            headers: headers,
          }
        )
        .then((res) => {
          console.log(res);
          alert(
            "Rolledback Successfull. Page will refresh"
          );
          window.location.reload();
          // window.location.href = "/";
        })
        .catch((err) => {
          console.log(err);
          alert("Rollback Error");
        });
    }
  }

  //console.info(comm.data);

  const unrecoList = comm.data.map((item, index) => (
    <TransRow key={index}>
      <TransTd style={tableWidth.created}>
        {dayjs(item.created).format("DD/MM/YYYY")}
      </TransTd>
      <TransTd style={tableWidth.partner}>{`${item.partner_id}`}</TransTd>
      <TransTd style={tableWidth.lead}>{item.lead_ref}</TransTd>
      <TransTd style={tableWidth.sale}>{item.sale_ref}</TransTd>
      <TransTd style={fundedWidth.amount}>{item.funded_amount}</TransTd>
      <TransTd style={fundedWidth.dealType}>{item.funded_type}</TransTd>
      <TransTd style={fundedWidth.date}>
        {dayjs(item.funded_date).format("DD/MM/YYYY")}
      </TransTd>
      <TransTd style={commWidth.broker}>{item.lender_name}</TransTd>
      <TransTd style={commWidth.expected}>{item.commission_expected}</TransTd>
      <TransTd style={commWidth.scheduled}>
        {dayjs(item.scheduled_date).format("DD/MM/YYYY")}
      </TransTd>
      <TransTd style={commWidth.status}>{item.status}</TransTd>
      <TransTd style={commWidth.actionRollback}>
        <ConfirmButton
          other
          onClick={() => rollbackTransaction(item.commission_id, props.id)}
          style={{width: "auto"}}
        >
          Rollback
        </ConfirmButton>
      </TransTd>
    </TransRow>
  ));
  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={modalStyles}
      >
        <CloseButton onClick={closeModal}>&#10006;</CloseButton>
        <ModalHeaderWrapper>
          <PageHeader
            header="Confirmation"
            blurb={`Are you sure you wish to reconcile transaction ${props.id}:`}
          />
          <ModalRow>
            <ModalTd>
              {dayjs(props.data.data[index].date).format("DD/MM/YYYY")}
            </ModalTd>
            <ModalTd>{props.data.data[index].text}</ModalTd>
            <ModalTd>{props.data.data[index].amount}</ModalTd>
          </ModalRow>
        </ModalHeaderWrapper>
        <ModalBlurbWrapper>
          <PageHeader blurb="With the following payments:" />
        </ModalBlurbWrapper>
        <ModalTableRecoConfirm
          checked={checked}
          close={closeModal}
          id={props.data.data[index].tx_id}
        />
      </Modal>
      <TableWrapper className="scrollableShadows"  style={{overflow:'scroll'}}>
        <Table>
          <TopHeaderRow>
            <TopTableHeader>Funded Details</TopTableHeader>
            <TopTableHeader>Commission Details</TopTableHeader>
          </TopHeaderRow>
          <TableHead style={tableContentMinddle.thead}>
            <TableHeader style={tableWidth.created}>Created</TableHeader>
            <TableHeader style={tableWidth.partner}>Partner ID</TableHeader>
            <TableHeader style={tableWidth.lead}>Lead Ref</TableHeader>
            <TableHeader style={tableWidth.sale}>Sale Ref</TableHeader>
            <TableHeader style={fundedWidth.amount}>Amount</TableHeader>
            <TableHeader style={fundedWidth.dealType}>Deal Type</TableHeader>
            <TableHeader style={fundedWidth.date}>When</TableHeader>
            <TableHeader style={commWidth.broker}>Lender</TableHeader>
            <TableHeader style={commWidth.expected}>Expected</TableHeader>
            <TableHeader style={commWidth.scheduled}>Scheduled</TableHeader>
            <TableHeader style={commWidth.status}>Status</TableHeader>
            <TableHeader style={commWidth.actionRollback}>Action</TableHeader>
          </TableHead>
          <TableBody style={tableContentMinddle.tbody}>
            {loading == true ? (
              <SpinnerUtit display={"flex"} loading={loading} />
            ) : unrecoList.length > 0 ? (
              unrecoList
            ) : (
              <NoData />
            )}
          </TableBody>
        </Table>
      </TableWrapper>
    </>
  );
});

export default ModalTableReco;
