import { Nav, NavHeader, Logout } from "./Navbar.styles";
import { BrowserRouter as Router, Link } from "react-router-dom";
import React from "react";

const Navbar = () => {
  return (
    <>
      <Nav>
        <NavHeader to="/">Lend Recon Service</NavHeader>
      </Nav>
    </>
  );
};

export default Navbar;
