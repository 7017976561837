import styled from "styled-components";

export const CloseButton = styled.a`
  background-color: transparent;
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.5rem;
  font-family: 700;
  color: #77777780;
  :hover {
    cursor: pointer;
    color: #777777;
    transition: ease .2s;
  }
`;