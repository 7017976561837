import React, { useState } from "react";
import {
  TableWrapper,
  Table,
  TableHead,
  HeaderRow,
  TableHeader,
  TransRow,
  TransTd,
} from "../ModalTable/ModalTable.styles";
import {
  TableBody,
  NoteWrapper,
  NoteInput,
  NoteTitle,
  NoteButton,
  ConfirmButton,
  DenyButton,
  ModalButtonWrapper,
  ConfirmWrapper
} from "./CommisionNotesStyle";
const baseURL = process.env.REACT_APP_API_URL;
const dayjs = require("dayjs");
const axios = require("axios").default;

const CommisionNotes = (data, props) => {
  const token = window.localStorage.getItem("token");
  const headers = {
    "Authorization": `Bearer ${token}`
  };
  const splits = [];
  const splitArray = data.checked.forEach((comm) => {
    splits.push(comm.commission_id.toString());
  });
  const tableWidth = {
    created: {
      width: "10%",
    },
    lead: {
      width: "10%",
    },
    sale: {
      width: "10%",
    },
    comm: {
      width: "30%",
    },
    date: {
      width: "10%",
    },
    lender: {
      width: "30%",
    },
  };


  let commId = "";


  const [note, setNote] = useState("");
  const [noteWasAdded, setNoteWasAdded] = useState(false);

  function saveNote(commision_id){
    axios
    .post(
      baseURL + `/update-commision-notes/${data.comid}`, {
        note: note,
      },{
        headers:headers
      }
    ).then(res => {
      console.log(res.data);
      if(res.data.success){
        setNoteWasAdded(true);
      }
    }
    ).catch(err => {
      console.log(err);
    });
  }







  return (
        <div>
          <NoteWrapper>
            <NoteTitle>Commision notes: </NoteTitle>
            <NoteInput
              type="text"
              placeholder="Add a note..."
              onChange={(e) => setNote(e.target.value)}
              defaultValue={data.comnote}
            />
            <NoteButton onClick={saveNote}>Set Note</NoteButton>
            <>{noteWasAdded ? <ConfirmWrapper style={{ color: "#67BE23", fontWeight: "500", margin: "1rem 0rem 0rem 0rem"}}>
              Note was added ✔
            </ConfirmWrapper> : null} </>
          </NoteWrapper>
      </div>

  );
};

export default CommisionNotes;
