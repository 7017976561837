import { useState, useEffect, Component } from "react";
import { PaginationHeader, PaginationWrapper, PageDropdown } from "../Pagination/Pagination.styles";
import { CloseButton } from "../Modal/Modal.styles.js";
import { DescFilter } from "../Pagination/Pagination.styles";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import {
  TableWrapper,
  Table,
  TableBody,
  TableHead,
  HeaderRow,
  TableHeader,
  TransRow,
  TransTd,
  TableButton,
} from "../TransactionTable/TransactionTable.styles";
import {
  NoteWrapper,
  NoteInput,
  NoteTitle,
  NoteButton,
  ConfirmWrapper,
} from "../ModalTableUnrecoConfirm/ModalTableUnrecoConfirm.styles";
import {
  ModalHeaderWrapper,
  ModalRow,
  ModalTd,
} from "../ModalTable/ModalTable.styles";
import PageHeader from "../PageHeader/PageHeader";
import SingleUnrecoTrans from "../../pages/SingleUnrecoTrans";
import Modal from "react-modal";
import Select from "react-select";

const baseURL = process.env.REACT_APP_API_URL;
const dayjs = require("dayjs");
const axios = require("axios").default;

const TransactionTableUnre = (props) => {
  const token = window.localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const max = props.max;
  const data = props.data;
  const [unrecoIndex, setUnrecoIndex] = useState("");
  const [unrecoId, setUnrecoId] = useState("");
  const [unrecoData, setUnrecoData] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [editModalIsOpen, setModalIsOpen] = useState(false);
  const [editTrans, setEditTrans] = useState();
  const [noteOpen, setNoteOpen] = useState(false);
  const [notes, setNotes] = useState("");
  const [newNote, setNewNote] = useState("");
  const [txId, setTxId] = useState("");
  const [noteId, setNoteId] = useState("");
  const [updated, setUpdated] = useState(false);
  const [note, setNote] = useState("");
  const [noteWasAdded, setNoteWasAdded] = useState(false);
  const [noteNewVisible, setToggleNewNoteVisibilty] = useState(true);

  const modalStyles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.28)",
    },
    content: {
      display: "flex",
      width: "1000px",
      height: "600px",
      flexDirection: "column",
      inset: "10%",
      justifyContent: "flex-start",
      margin: "0 auto",
    },
  };

  const modalStylesSmall = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.28)",
    },
    content: {
      display: "flex",
      width: "800px",
      height: "350px",
      flexDirection: "column",
      inset: "10%",
      justifyContent: "flex-start",
      margin: "0 auto",
    },
  };

  const widthStyles = {
    id: {
      width: "5%",
    },
    date: {
      width: "15%",
    },
    desc: {
      width: "40%",
    },
    amount: {
      width: "10%",
    },
    note: {
      width: "10%",
    },
    action: {
      width: "10%",
    },
  };

  const modalWidths = {
    date: {
      width: "12.5%",
    },
    note: {
      width: "60%",
    },
    id: {
      width: "10%",
    },
    update: {
      width: "15%",
    },
  };

  const notesModalStyles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.28)",
    },
    content: {
      display: "flex",
      width: "600px",
      height: "300px",
      flexDirection: "column",
      inset: "25%",
      justifyContent: "flex-start",
      margin: "0 auto",
    },
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function openNoteModal() {
    setNoteOpen(true);
  }

  function closeNoteModal() {
    setNoteOpen(false);
    setUpdated(false);
  }

  function updateNotesModal(id) {
    openNoteModal();
    setNoteId(id);
  }

  function openEditModal(item) {
    setEditTrans(item);
    setModalIsOpen(true);
  }

  function closeEditModal() {
    setEditTrans(null);
    setModalIsOpen(false);
  }

  function updateNote(id) {
    axios
      .post(
        baseURL + `/edit-note/${id}`, {
        note: newNote,
      },
        {
          headers: headers
        },
      )
      .then((response) => {
        setUpdated(true);
      });
  }

  function updateTrans() {
    delete editTrans.note;
    axios
      .post(
        baseURL + `/update-payment/${editTrans.tx_id}`, 
          editTrans,
        {
          headers: headers
        },
      )
      .then((response) => {
        if (response.data.success) {
          window.location.reload();
        }
      });
  }

  function notesModal(id) {
    openModal();
    setTxId(id);
    axios
      .get(baseURL + `/get-notes/${id}`, {
        headers: headers
      })
      .then((response) => {
        setNotes(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(
    (tx_index) => {
      if (unrecoIndex === "") {
        setUnrecoIndex(tx_index);
      }
    },
    [unrecoIndex]
  );

  if (!data) return null;

  function singlePassData(tx_id, index, data) {
    setUnrecoId(tx_id);
    setUnrecoIndex(index);
    setUnrecoData(data);
  }

  function addNewNote(id) {
    axios
      .post(baseURL + `/add-note/${id}`, {
        note: note,
      },
        {
          headers: headers
        })
      .then((response) => {
        setNoteWasAdded(true);
        axios
          .get(baseURL + `/get-notes/${id}`, {
            headers: headers
          })
          .then((response) => {
            setNotes(response.data.data);
            console.log(notes);
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function changeNewNoteVisbilty() {
    setToggleNewNoteVisibilty(!noteNewVisible);
  }

  const listItems = data.data.map((item, tx_index) => (
    <TransRow>
      <TransTd style={widthStyles.id}>{item.tx_id}</TransTd>
      <TransTd style={widthStyles.date}>
        {dayjs(item.date).format("DD/MM/YYYY")}
      </TransTd>
      <TransTd style={widthStyles.desc}>{item.text}</TransTd>
      <TransTd style={widthStyles.amount}>{item.amount}</TransTd>
      <TransTd style={widthStyles.note}>
        {item.note == true ? (
          <TableButton onClick={() => notesModal(item.tx_id)}>
            View Note
          </TableButton>
        ) : null}
      </TransTd>

      <TransTd style={widthStyles.action}>
        {item.show_unrecon_list == true ? (
            <Link to={`/reconciled/${item.tx_id}`} state={unrecoId}>
              <TableButton
                onClick={() => singlePassData(item.tx_id, tx_index, data, max)}
              >
                View and Rollback
              </TableButton>
            </Link>
        ) : null}
      </TransTd>

      <Switch>
        <Link to={`/unreconciled/${item.tx_id}`} state={unrecoId}>
          <TableButton
            onClick={() => singlePassData(item.tx_id, tx_index, data, max)}
          >
            Start Manual Recon
          </TableButton>
        </Link>
        <Route
          path={`unreconciled/${item.tx_id}`}
          component={() => (
            <SingleUnrecoTrans index={tx_index} max={max} data={data} />
          )}
        />
      </Switch>

      <TransTd style={widthStyles.action}>
        <TableButton onClick={() => openEditModal(item)}>
          Edit Transaction
        </TableButton>
      </TransTd>
    </TransRow>
  ));

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={modalStyles}
      >
        <CloseButton onClick={closeModal}>&#10006;</CloseButton>
        <ModalHeaderWrapper>
          <PageHeader header={`Notes for Un-Reconciled Transaction: ${txId}`} />

          <NoteWrapper>
            <NoteTitle>Add a new note: <a href="javascript:void(0)" onClick={() => changeNewNoteVisbilty()}>{noteNewVisible ? "Hide" : "Show"}</a>
            </NoteTitle>
            <>{noteNewVisible == true ? <NoteInput type="text" placeholder="Add a note..." onChange={(e) => setNote(e.target.value)} /> : null}</>
            <>{noteNewVisible == true ? <NoteButton onClick={() => { addNewNote(txId) }} >Add Note</NoteButton> : ''}</>
            <>{noteWasAdded ? <ConfirmWrapper style={{ color: "#67BE23", fontWeight: "500", margin: "1rem 0rem 0rem 0rem" }}>
              Note was added ✔
            </ConfirmWrapper> : ''} </>
          </NoteWrapper>

          <TransRow>
            <TransTd style={modalWidths.date}>Date Created</TransTd>
            <TransTd style={modalWidths.date}>Date Updated</TransTd>
            <TransTd style={modalWidths.note}>Note</TransTd>
            <TransTd style={modalWidths.id}>Note ID</TransTd>
            <TransTd style={modalWidths.update}></TransTd>
          </TransRow>

          {notes &&
            notes.map((item) => (
              <>
                <TransRow>
                  <TransTd style={modalWidths.date}>
                    {dayjs(item.created).format("DD/MM/YYYY")}
                  </TransTd>
                  <TransTd style={modalWidths.date}>
                    {item.updated ? dayjs(item.updated).format("DD/MM/YYYY") : null}
                  </TransTd>
                  <TransTd style={modalWidths.note}>{item.note}</TransTd>
                  <TransTd style={modalWidths.id}>{item.note_id}</TransTd>
                  <TransTd style={modalWidths.update}>
                    <TableButton onClick={() => updateNotesModal(item.note_id)}>
                      Edit Note
                    </TableButton>
                  </TransTd>
                </TransRow>
                <Modal
                  isOpen={noteOpen}
                  onRequestClose={closeNoteModal}
                  style={notesModalStyles}
                >
                  <CloseButton onClick={closeNoteModal}>&#10006;</CloseButton>
                  <PageHeader header={`Update note for Note ID: ${noteId}`} />
                  <NoteWrapper>
                    <NoteTitle>Transaction notes: </NoteTitle>
                    <NoteInput
                      type="text"
                      placeholder="Add a note..."
                      onChange={(e) => setNewNote(e.target.value)}
                    />
                    <NoteButton onClick={() => updateNote(noteId)}>
                      Update Note
                    </NoteButton>
                    {updated ? (
                      <ConfirmWrapper style={{ color: "green" }}>
                        The note with Note ID {noteId} has been updated ✔
                      </ConfirmWrapper>
                    ) : null}
                  </NoteWrapper>
                </Modal>
              </>
            ))}
        </ModalHeaderWrapper>
      </Modal>

      <Modal
        isOpen={editModalIsOpen}
        onRequestClose={closeEditModal}
        style={modalStylesSmall}
      >
        <CloseButton onClick={closeEditModal}>&#10006;</CloseButton>
        <ModalHeaderWrapper>
        <PageHeader header={`Edit Un-Reconciled Transaction: ${editTrans?.tx_id}`} />
          <TransRow>
            <div style={{marginRight: '5px'}}>
              <PaginationHeader>Date: </PaginationHeader>
              <PageDropdown>
                <DescFilter
                  id="dateFilter"
                  small
                  placeholder="Filter by Date"
                  name="date"
                  type="date"
                  value={dayjs(editTrans?.date).format("YYYY-MM-DD")}
                  onChange={(event) => {setEditTrans(prevState => ({
                    ...prevState,
                    date: event.target.value
                  }))}}
                />
              </PageDropdown>
            </div>
            <div style={{marginRight: '5px'}}>
              <PaginationHeader>Description: </PaginationHeader>
              <PageDropdown>
                <DescFilter
                  placeholder=""
                  type="text"
                  value={editTrans?.text}
                  onChange={(event) => {setEditTrans(prevState => ({
                    ...prevState,
                    text: event.target.value
                  }))}}
                />
              </PageDropdown>
            </div>
            <div style={{marginRight: '5px'}}>
              <PaginationHeader>Status: </PaginationHeader>
              <PageDropdown>
                <Select
                  value={editTrans?.status ? { label: "Active", value: 1 } : { label: "Inactive", value: 0 }}
                  options={[
                    { label: "Active", value: 1 },
                    { label: "Inactive", value: 0 }
                  ]}
                  name="status"
                  onChange={(event) => {setEditTrans(prevState => ({
                    ...prevState,
                    status: event.value
                  }))}}
                />
              </PageDropdown>
            </div>
          </TransRow>
          <TransRow>
            <NoteButton onClick={() => {updateTrans()}} >Update</NoteButton> 
          </TransRow>
        </ModalHeaderWrapper>
      </Modal>

      <TableWrapper>
        <Table>
          <TableHead>
            <HeaderRow>
              <TableHeader style={widthStyles.id}>ID</TableHeader>
              <TableHeader style={widthStyles.date}>
                Transaction Date
              </TableHeader>
              <TableHeader style={widthStyles.desc}>Description</TableHeader>
              <TableHeader style={widthStyles.amount}>Amount</TableHeader>
              <TableHeader style={widthStyles.note}>Note</TableHeader>
              <TableHeader style={widthStyles.action}>Reconciled</TableHeader>        
              <TableHeader style={widthStyles.action}>Actions</TableHeader>
              <TableHeader style={widthStyles.action}></TableHeader>
            </HeaderRow>
          </TableHead>
          <TableBody>{listItems}</TableBody>
        </Table>
      </TableWrapper>
    </>
  );
};

export default TransactionTableUnre;
