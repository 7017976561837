import { useState, useEffect } from "react";
import Nav from "./Components/Navbar/Navbar";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useParams,
} from "react-router-dom";
import Home from "./pages/Home";
import ConfirmAuto from "./pages/ConfirmAuto";
import AllTrans from "./pages/AllTrans";
import UnrecoTrans from "./pages/UnrecoTrans";
import SingleUnrecoTrans from "./pages/SingleUnrecoTrans";
import SingleRecoTrans from "./pages/SingleRecoTrans";
import ReconHistory from "./pages/ReconHistory"

import axios from "axios";
import Login from "../src/Components/Login/Login";
const baseURL = process.env.REACT_APP_API_URL;

function App() {

  const [auth, setAuth] = useState(false);

  function TxId() {
    let { tx_id } = useParams();
    let { tx_index } = useParams();
    return <SingleUnrecoTrans id={tx_id} index={tx_index} />;
  }

  function ReconTxId() {
    let { tx_id } = useParams();
    let { tx_index } = useParams();
    return <SingleRecoTrans id={tx_id} index={tx_index} />;
  }

  const paramsMatch = window.location.href.match(/\?.+/);
  if (paramsMatch) {
    const params = new URLSearchParams(paramsMatch[0]);
    const authToken = params.get("token");
    console.log(authToken)
    if (authToken) {
      localStorage.token = authToken;
    }
  }

  // check if token is in the local storage, if it isn't AUTH stays false and nothing is displayed
  useEffect(() => {
    if(window.localStorage.getItem("token") === null) {
      setAuth(false)
    } else {
      setAuth(true)
    }
  }, [auth])

  return (
    <>
      {!auth ? (
        <Login />
      ) : (
        <Router>
          <Nav />
          <Switch>
            <Route exact path={`/`} component={Home} />
            <Route path="/confirm" component={ConfirmAuto} />
            <Route path="/transactions" component={AllTrans} />
            <Route path="/unrecotransactions" component={UnrecoTrans} />
            <Route path={`/unreconciled/:tx_id`}>
              <TxId />
            </Route>
            <Route path={`/reconciled/:tx_id`}>
              <ReconTxId />
            </Route>
            <Route path="/reconshistory" component={ReconHistory} />
          </Switch>
        </Router>
      )}
    </>
  );
}

export default App;
