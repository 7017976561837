import styled from "styled-components"

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
  background-color: #304ff8;
  color: white;
  font-family: "Roboto", sans-serif;
`;

export const LendLink = styled.a`
  color: white;
  text-decoration: underline;
`

export const LendLogo = styled.img`
  display: flex;
  width: auto;
  height: 40px;
  margin-bottom: 1rem;
  filter: brightness(0) invert(1);
  z-index: 10;
`;