import { useState, useEffect, Component } from "react";
import {
  TableWrapper,
  Table,
  TableBody,
  TableHead,
  HeaderRow,
  TableHeader,
  TransRow,
  TransTd,
  TableButton,
} from "../TransactionReconHistory/TransactionTableRecHistory.styles";
import {
  NoteWrapper,
  NoteInput,
  NoteTitle,
  NoteButton,
  ConfirmWrapper,
} from "../ModalTableUnrecoConfirm/ModalTableUnrecoConfirm.styles"; // will check this HH
import {
  ModalHeaderWrapper,
  ModalRow,
  ModalTd,
} from "../ModalTable/ModalTable.styles";
import { CloseButton } from "../Modal/Modal.styles.js";
import PageHeader from "../PageHeader/PageHeader";
import SingleRecoTrans from "../../pages/SingleRecoTrans";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import Modal from "react-modal";

import TransactionReconHistorySeach from "./TransactionHistorySearch";
import NoData from "../Utility/NoData";


const baseURL = process.env.REACT_APP_API_URL;
const dayjs = require("dayjs");
const axios = require("axios").default;

const TransactionTableRecHistory = (props) => {
  const token = window.localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const max = props.max;
  const data = props.data;
  const [unrecoIndex, setUnrecoIndex] = useState("");
  const [unrecoId, setUnrecoId] = useState("");
  const [unrecoData, setUnrecoData] = useState("");

  const [modalIsOpen, setIsOpen] = useState(false);
  const [noteOpen, setNoteOpen] = useState(false);

  const [search, setSearch] = useState("");

  const modalStyles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.28)",
    },
    content: {
      display: "flex",
      width: "1000px",
      height: "600px",
      flexDirection: "column",
      inset: "10%",
      justifyContent: "flex-start",
      margin: "0 auto",
    },
  };

  const widthStyles = {
    id: {
      width: "5%",
    },
    date: {
      width: "12.5%",
    },
    recondate: {
      width: "12.5%",
    },
    desc: {
      width: "35%",
    },
    amount: {
      width: "5%",
    },
    recontype: {
      width: "10%",
    },
    note: {
      width: "10%",
    },
    action: {
      width: "10%",
    },
  };

  const modalWidths = {
    date: {
      width: "12.5%",
    },
    note: {
      width: "60%",
    },
    id: {
      width: "10%",
    },
    update: {
      width: "15%",
    },
  };

  const notesModalStyles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.28)",
    },
    content: {
      display: "flex",
      width: "600px",
      height: "300px",
      flexDirection: "column",
      inset: "25%",
      justifyContent: "flex-start",
      margin: "0 auto",
    },
  };

  const [notes, setNotes] = useState("");
  const [newNote, setNewNote] = useState("");
  const [txId, setTxId] = useState("");
  const [noteId, setNoteId] = useState("");
  const [updated, setUpdated] = useState(false);
  const [noteData, setNoteData] = useState();

  const [hasNote, setHasNote] = useState(false);

  useEffect(() => {
    function checkNote(id) {
      axios
        .get(baseURL + `/get-notes/${id}`, {
          headers: headers,
        })
        .then((response) => {
          console.log(response);
          if (response.data.data.length > 0) {
            setHasNote(true);
            console.log(hasNote);
            return;
          } else {
            return;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      checkNote();
    }
  }, [token]);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function openNoteModal() {
    setNoteOpen(true);
  }

  function closeNoteModal() {
    setNoteOpen(false);
    setUpdated(false);
  }

  function updateNotesModal(id) {
    openNoteModal();
    setNoteId(id);
    console.log(noteId);
  }

  function updateNote(id) {
    axios
      .post(
        baseURL + `/edit-note/${id}`,
        {
          note: newNote,
        },
        {
          headers: headers,
        }
      )
      .then((response) => {
        console.log(response);
        setUpdated(true);
      });
  }

  function notesModal(id) {
    openModal();
    console.log(id);
    setTxId(id);
    axios
      .get(baseURL + `/get-notes/${id}`, {
        headers: headers,
      })
      .then((response) => {
        setNotes(response.data.data);
        console.log(notes);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(
    (tx_index) => {
      if (unrecoIndex === "") {
        setUnrecoIndex(tx_index);
      }
    },
    [unrecoIndex]
  );

  if (!data) return null;

  function singlePassData(tx_id, index, data) {
    setUnrecoId(tx_id, () => {
      console.log(unrecoId);
    });
    setUnrecoIndex(index, () => {
      console.log(unrecoIndex);
    });
    setUnrecoData(data, () => {
      console.log(unrecoData);
    });
  }

  function setOther(id) {
    axios
      .post(baseURL + `/set-other/${id}`, {
        headers: headers,
      })
      .then((response) => {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function rollbackTx() {
    console.log("rolllback");
  }

  function searchReconList() {
    // get text box value from
    var search = document.getElementById("keyword_search").value;
    setSearch(search);
  }

  function filterList(listItems, search) {
    if (search === "") {
      return listItems;
    } else {
      return listItems.map((item, index) => {
        if (
            item.text.toLowerCase().includes(search.toLowerCase()) ||
            item.amount.toLowerCase().includes(search.toLowerCase())
        ){
          return item;
        }
      });
    }
  }


  if(data.data.length === 0){
    return (
      <NoData/>
    )
  }
  

  const listItems = filterList(data.data, search).map((item, tx_index) =>
    item != null && item != "" && item != undefined && item != "undefined" ? (
      <TransRow>
        <TransTd style={widthStyles.id}>{item.tx_id}</TransTd>
        <TransTd style={widthStyles.date}>
          {dayjs(item.date).format("DD/MM/YYYY")}
        </TransTd>
        <TransTd style={widthStyles.recondate}>

          {item.recon_date != null ? dayjs(item.recon_date).format("DD/MM/YYYY"): "-"}
          {/*dayjs(item.reconn_date).format("DD/MM/YYYY")*/}
          
        </TransTd>
        <TransTd style={widthStyles.desc}>{item.text}</TransTd>
        <TransTd style={widthStyles.amount}>{item.amount}</TransTd>
        
        <TransTd style={widthStyles.recontype}>{item.partial == 1 ? 'Partial':'Full'}</TransTd>

        <TransTd style={widthStyles.note}>
          {item.note == true ? (
            <TableButton onClick={() => notesModal(item.tx_id)}>
              View Note
            </TableButton>
          ) : null}
        </TransTd>

        <TransTd style={widthStyles.action}>
          <Switch>
            <Link to={`/reconciled/${item.tx_id}`} state={unrecoId}>
              <TableButton
                onClick={() => singlePassData(item.tx_id, tx_index, data, max)}
              >
                View and Rollback
              </TableButton>
            </Link>

            <Route
              path={`reconciled/${item.tx_id}`}
              component={() => (
                <SingleRecoTrans index={tx_index} max={max} data={data} />
              )}
            />
          </Switch>

         </TransTd>


      </TransRow>
    ) : null
  );



  return (
    <>
      {/* this is note section */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={modalStyles}
      >
        <CloseButton onClick={closeModal}>&#10006;</CloseButton>
        <ModalHeaderWrapper>
          <PageHeader header={`Notes for Un-Reconciled Transaction: ${txId}`} />
          <TransRow>
            <TransTd style={modalWidths.date}>Date Created</TransTd>
            <TransTd style={modalWidths.date}>Date Updated</TransTd>
            <TransTd style={modalWidths.note}>Note</TransTd>
            <TransTd style={modalWidths.id}>Note ID</TransTd>
            <TransTd style={modalWidths.update}>Update</TransTd>
          </TransRow>
          {notes &&
            notes.map((item) => (
              <>
                <TransRow>
                  <TransTd style={modalWidths.date}>
                    {dayjs(item.created).format("DD/MM/YYYY")}
                  </TransTd>
                  <TransTd style={modalWidths.date}>
                    {dayjs(item.updated).format("DD/MM/YYYY")}
                  </TransTd>
                  <TransTd style={modalWidths.note}>{item.note}</TransTd>
                  <TransTd style={modalWidths.id}>{item.note_id}</TransTd>
                  <TransTd style={modalWidths.update}>
                    <TableButton onClick={() => updateNotesModal(item.note_id)}>
                      Update Note
                    </TableButton>
                  </TransTd>
                </TransRow>
                <Modal
                  isOpen={noteOpen}
                  onRequestClose={closeNoteModal}
                  style={notesModalStyles}
                >
                  <CloseButton onClick={closeNoteModal}>&#10006;</CloseButton>
                  <PageHeader header={`Update note for Note ID: ${noteId}`} />
                  <NoteWrapper>
                    <NoteTitle>Transaction notes: </NoteTitle>
                    <NoteInput
                      type="text"
                      placeholder="Add a note..."
                      onChange={(e) => setNewNote(e.target.value)}
                    />
                    <NoteButton onClick={() => updateNote(noteId)}>
                      Update Note
                    </NoteButton>
                    {updated ? (
                      <ConfirmWrapper style={{ color: "green" }}>
                        The note with Note ID {noteId} has been updated ✔
                      </ConfirmWrapper>
                    ) : null}
                  </NoteWrapper>
                </Modal>
              </>
            ))}
        </ModalHeaderWrapper>
      </Modal>

      <TransactionReconHistorySeach searchReconList={searchReconList} />

      <TableWrapper>
        <Table>
          <TableHead>
            <HeaderRow>
              <TableHeader style={widthStyles.id}>ID</TableHeader>
              <TableHeader style={widthStyles.date}>
                Transaction Date
              </TableHeader>
              <TableHeader style={widthStyles.recondate}>
                Recon Date
              </TableHeader>
              <TableHeader style={widthStyles.desc}>Description</TableHeader>
              <TableHeader style={widthStyles.amount}>Amount</TableHeader>
              <TableHeader style={widthStyles.recontype}>Recon Type</TableHeader>
              <TableHeader style={widthStyles.note}>Note</TableHeader>
              <TableHeader style={widthStyles.action}>Actions</TableHeader>
            </HeaderRow>
          </TableHead>
          <TableBody>{listItems}</TableBody>
        </Table>
      </TableWrapper>
    </>
  );
};

export default TransactionTableRecHistory;
