import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";

export const Nav = styled.nav`
  position: relative;
  width: 100%;
  height: 48px;
  background-color: #3c3c3c;
	display: flex;
	justify-content: space-between;
	vertical-align: middle;
	align-items: center;
`;

export const NavHeader = styled(LinkR)`
  color: white;
  font-size: 18px;
  display: inline-flex;
  justify-content: space-between;
  margin: 0 4rem;
  font-weight: 400;
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }

  @media screen and (max-width: 480px) {
    font-size: 14px;
    margin: 0 2rem;
  }
`;

export const Logout = styled.a`
  color: white;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
  margin: 0 4rem;

  :hover {
    text-decoration: underline;
  }

  @media screen and (max-width: 480px) {
    font-size: 12px;
    margin: 0 2rem;
  }
`;