import styled from "styled-components";

export const TableBody = styled.tbody`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  height: 150px;
  overflow-y: auto;
`;

export const NoteWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const NoteInput = styled.input`
  display: flex;
  height: 75px;
  width: 400px;
  border: 1px solid #e1e1e1;
  resize: both;

  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
  padding: 4px 10px;
  color: hsl(0, 0%, 50%);

  :hover {
    cursor: text;
  }

  ::placeholder {
    color: #d6d6d6;
  }

  :focus {
    border-color: #2684ff;
    box-shadow: 0 0 0 1px #2684ff;
  }
`;

export const NoteTitle = styled.p`
  color: #9E9E9E;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  padding-top: .5rem;
  // edit margin later if need
  margin: 1rem 0rem 1rem;
`

export const NoteButton = styled.button`
  background-color: #1572de;
  width: 100px;
  height: 30px;
  border-radius: 4px;
  font-size: 15px;
  outline: none;
  border: none;
  cursor: pointer;
  margin: 1rem 0rem 0rem;
  color: white;
  :hover {
    background-color: #0c55ab;
    transition: ease 0.2s;
  }

  :active {
    border: 1px solid #d6d6d6;
    border-radius: 4px;
  }
`
export const ConfirmButton = styled.button`
  background-color: #1572de;
  width: ${({ partial }) => (partial) ? "100px" : "200px"};
  height: 30px;
  border-radius: 4px;
  font-size: 15px;
  outline: none;
  border: none;
  cursor: pointer;
  margin: 0 1rem 0 0;
  color: white;
  :hover {
    background-color: #0c55ab;
    transition: ease 0.2s;
  }

  :active {
    border: 1px solid #d6d6d6;
    border-radius: 4px;
  }
`;

export const DenyButton = styled.button`
  background-color: #bf3131;
  width: 200px;
  height: 30px;
  border-radius: 4px;
  font-size: 15px;
  outline: none;
  border: none;
  cursor: pointer;
  color: white;

  :hover {
    background-color: #962727;
    transition: ease 0.2s;
  }

  :active {
    border: 1px solid #d6d6d6;
    border-radius: 4px;
  }
`;

export const ModalButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  margin: 1rem 0rem 0rem;
`;

export const ConfirmWrapper = styled.div`
  color: #9E9E9E;
  width: auto;
  height: 2rem;
  display: flex;
  margin: 0 1rem;
  justify-content: flex-start;
`;