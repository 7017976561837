import styled from "styled-components";

export const Header = styled.h1`
  color: #1b1f34;
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;

  // edit margin later if needed
  margin: 0;
`;

export const HeaderBlurb = styled.p`
  color: #9E9E9E;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  padding-top: .5rem;
  // edit margin later if need
  margin: 0;
`;
