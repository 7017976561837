import React, { useState } from "react";
import {
  TableWrapper,
  Table,
  TableHead,
  HeaderRow,
  TableHeader,
  TransRow,
  TransTd,
} from "../ModalTable/ModalTable.styles";
import {
  NoRecoDiv,
  NoRecoBlurb,
} from "../ModalTableUnreco/ModalTableUnreco.styles";
import {
  TableBody,
  NoteWrapper,
  NoteInput,
  NoteTitle,
  NoteButton,
  ConfirmButton,
  DenyButton,
  ModalButtonWrapper,
  ConfirmWrapper
} from "./ModalTableUnrecoConfirm.styles";
const baseURL = process.env.REACT_APP_API_URL;
const dayjs = require("dayjs");
const axios = require("axios").default;

const ModalTable = (data, props) => {
  const token = window.localStorage.getItem("token");
  const headers = {
    "Authorization": `Bearer ${token}`
  };
  const splits = [];
  const splitArray = data.checked.forEach((comm) => {
    splits.push(comm.commission_id.toString());
  });
  const tableWidth = {
    created: {
      width: "10%",
    },
    lead: {
      width: "10%",
    },
    sale: {
      width: "10%",
    },
    comm: {
      width: "30%",
    },
    date: {
      width: "10%",
    },
    lender: {
      width: "30%",
    },
  };


  let commId = "";


  const [confirmed, setConfirmed] = useState(false);
  const [cancelled, setCancelled] = useState(false);

  const [note, setNote] = useState("");
  const [noteWasAdded, setNoteWasAdded] = useState(false);

  function addNote() {
    setNote()
    axios
      .post(
        baseURL + `/add-note/${data.id}`, {
          note: note
        },
        {
          headers: headers,
        })
      .then((response) => {
        console.log(response);
      });
      setNoteWasAdded(true)
  }

  function reconcilePayments() {
    if (data.checked.length === 1) {
      commId += data.checked[0].commission_id
      axios
        .post(
          baseURL + `/manual-match/${data.id}`,
          {
            commission_id: commId,
          },
          {
            headers: headers
          })
        .then((response) => {
          console.log(response);
        });
    } else if (data.checked.length > 1) {
      axios
        .post(
          baseURL + `/manual-match-split/${data.id}`,          
          {
            split: splits,
          },
          {
            headers: headers
          })
        .then((response) => {
          console.log(response);
        });
    }
    setConfirmed(true)
    setCancelled(false)
  }

  function cancelReconcile() {
    axios
      .post(baseURL + `/cancel-match/${data.id}`, {
        headers: headers
      })
      .then((response) => {
        console.log(response);
      });
      setConfirmed(false);
      setCancelled(true);
    }


  return (
    <>
      <TableWrapper>
        <Table>
          <TableHead>
            <HeaderRow>
              <TableHeader style={tableWidth.created}>Created</TableHeader>
              <TableHeader style={tableWidth.lead}>Lead Ref</TableHeader>
              <TableHeader style={tableWidth.sale}>Sale Ref</TableHeader>
              <TableHeader style={tableWidth.comm}>
                Expected Commission
              </TableHeader>
              <TableHeader style={tableWidth.date}>When</TableHeader>
              <TableHeader style={tableWidth.lender}>Lender</TableHeader>
            </HeaderRow>
          </TableHead>
          <TableBody>
            {data.checked.length === 0 ? (
              <NoRecoDiv>
                <NoRecoBlurb>No Transactions to Reconcile</NoRecoBlurb>
              </NoRecoDiv>
            ) : (
              data &&
              data.checked.map((item) => (
                <TransRow>
                  <TransTd style={tableWidth.created}>
                    {dayjs(item.created).format("DD/MM/YYYY")}
                  </TransTd>
                  <TransTd style={tableWidth.lead}>{item.lead_ref}</TransTd>
                  <TransTd style={tableWidth.sale}>{item.sale_ref}</TransTd>
                  <TransTd style={tableWidth.comm}>
                    {item.commission_expected}
                  </TransTd>
                  <TransTd style={tableWidth.date}>
                    {dayjs(item.scheduled_date).format("DD/MM/YYYY")}
                  </TransTd>
                  <TransTd style={tableWidth.lender}>
                    {item.lender_name}
                  </TransTd>
                </TransRow>
              ))
            )}
          </TableBody>
          <NoteWrapper>
            <NoteTitle>Transaction notes: </NoteTitle>
            <NoteInput
              type="text"
              placeholder="Add a note..."
              onChange={(e) => setNote(e.target.value)}
            />
            <NoteButton onClick={addNote}>Set Note</NoteButton>
            <>{noteWasAdded ? <ConfirmWrapper style={{ color: "#67BE23", fontWeight: "500", margin: "1rem 0rem 0rem 0rem"}}>
              Note was added ✔
            </ConfirmWrapper> : null} </>
          </NoteWrapper>
          <ModalButtonWrapper>
            <ConfirmButton onClick={reconcilePayments}>
              Confirm Match
            </ConfirmButton>
            <DenyButton onClick={cancelReconcile}>Cancel</DenyButton>
            {confirmed ? (
              <ConfirmWrapper style={{ color: "green" }}>
                You have confirmed ✔
              </ConfirmWrapper>
            ) : null}
            {cancelled ? (
              <ConfirmWrapper style={{ color: "#bf3131" }}>
                You have cancelled 🗙
              </ConfirmWrapper>
            ) : null}
          </ModalButtonWrapper>
        </Table>
      </TableWrapper>
    </>
  );
};

export default ModalTable;
