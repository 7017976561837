import { useState, useEffect } from "react";
import {
  TableWrapper,
  Table,
  TableBody,
  TableHead,
  HeaderRow,
  TableHeader,
  TransRow,
  TransTd,
  TableButton,
} from "./TransactionTableAll.styles";
const dayjs = require("dayjs");
const TransactionTable = (props) => {
  const data = props.data;
  const filteredLower = props.inputFilter.toLowerCase();

  const widthStyles = {
    id: {
      width: "5%",
    },
    date: {
      width: "15%",
    },
    desc: {
      width: "50%",
    },
    amount: {
      width: "10%",
    },
    action: {
      width: "15%",
    },
    reconciled: {
      width: "10%",
      justifyContent: "center"
    },
  };

  return (
    <TableWrapper>
      <Table>
        <TableHead>
          <HeaderRow>
            <TableHeader style={widthStyles.id}>ID</TableHeader>
            <TableHeader style={widthStyles.date}>Transaction Date</TableHeader>
            <TableHeader style={widthStyles.desc}>Description</TableHeader>
            <TableHeader style={widthStyles.amount}>Amount</TableHeader>
            <TableHeader style={widthStyles.reconciled}>Reconciled</TableHeader>
            <TableHeader style={widthStyles.reconciled}>Confirmed</TableHeader>
            <TableHeader style={widthStyles.reconciled}>Partial</TableHeader>
            <TableHeader style={widthStyles.reconciled}>Other</TableHeader>
          </HeaderRow>
        </TableHead>
        <TableBody>
          {data.data
            .filter(
              (text) =>{
                return (text.text.toLowerCase().includes(filteredLower) || 
                        text.amount.toUpperCase().includes(filteredLower) ||
                        props.inputFilter === "")
                //console.log(text);
              }
            )
            .map((item) => (
              <TransRow>
                <TransTd style={widthStyles.id}>{item.tx_id}</TransTd>
                <TransTd style={widthStyles.date}>
                  {dayjs(item.date).format("DD/MM/YYYY")}
                </TransTd>
                <TransTd style={widthStyles.desc}>{item.text}</TransTd>
                <TransTd style={widthStyles.amount}>{item.amount}</TransTd>
                <TransTd className="tickBoxes" style={widthStyles.reconciled}>
                  {item.reconciled !== 0 ? "✔" : ""}
                </TransTd>
                <TransTd className="tickBoxes" style={widthStyles.reconciled}>
                  {item.commission_id || item.split !== null ? "✔" : ""}
                </TransTd>
                <TransTd className="tickBoxes" style={widthStyles.reconciled}>
                  {item.partial == 1 ? "✔" : ""}
                </TransTd>
                <TransTd className="tickBoxes" style={widthStyles.reconciled}>
                  {item.other == 1 ? "✔" : ""}
                </TransTd>
              </TransRow>
            ))}
        </TableBody>
      </Table>
    </TableWrapper>
  );
};

export default TransactionTable;
