import { useState, useEffect } from "react";
import { HeaderWrapper } from "../Components/Core/Core.styles";
import PageHeader from "../Components/PageHeader/PageHeader";
import TransactionTable from "../Components/TransactionTable/TransactionTable";
import Select from "react-select";
import {
  PaginationWrapper,
  PaginationHeader,
  PageDropdown,
} from "../Components/Pagination/Pagination.styles";
const baseURL = process.env.REACT_APP_API_URL;
const axios = require("axios").default;

const ConfirmAuto = () => {
  const [data, setData] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(99999);
  const token = window.localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  useEffect(() => {
    axios
      .get(baseURL + `/payments/${limit}/${page}`, {
        headers: headers
      })
      .then((response) => {
        setData(response.data);
        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [limit, page, token]);

if (!data) return null;

  const filtered = [];

  const filterData = data.data.filter((tx) => {
    if (tx.split != null) {
      filtered.push(tx);
      return tx.split;
    }
    else if (tx.commission_id != null) {
      filtered.push(tx);
      return tx.commission_id;
    }
  });
  console.log(filtered);

  //  PAGINATION CODE FOR THE FUTURE IN CASE WE NEED IT 
  // const totalPages = Math.ceil(filtered.length / data.pagination.perPage);

  // const firstPage = 1;
  // const multiPages = [];
  // // additional pages based on total pages requried
  // const addPages = Array.from(
  //   { length: totalPages - firstPage },
  //   (_, i) => i + firstPage
  // );

  // // initial page
  // const initPage = Array.from([totalPages - firstPage], (i) => i + firstPage);
  // console.log(initPage);

  // addPages.forEach((page) => {
  //   multiPages.push({ label: page, value: page });
  // });
  // initPage.forEach((page) => {
  //   multiPages.push({ label: page, value: page });
  // });
  // console.log(multiPages[multiPages.length - 1]);
  // console.log(multiPages);

  
  return (
    <>
      <HeaderWrapper>
        <PageHeader
          header="Confirm Auto-Reconciliations"
          blurb="Bank transactions that have been auto-reconciled by this service need to be confirmed as a correct match."
        />
      </HeaderWrapper>
      <>
        <PaginationWrapper style={{ width: "350px", margin: "0rem" }}>
        </PaginationWrapper>
      </>
      <TransactionTable limit={limit} page={page} data={data} filterData={filterData}/>
    </>
  );
};

export default ConfirmAuto;
