import styled from "styled-components";

export const TableWrapper = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
`;

export const Table = styled.table`
  width: 100%;
  max-width: 94%;
  margin: 0 auto;
`;

export const TableBody = styled.tbody`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
`;

export const TableHead = styled.thead`
  width: 100%;
  display: flex;
  padding: 5px 0;
  border-bottom: 1px solid #e1dede;
  justify-content: space-around;
`;

export const HeaderRow = styled.tr`
  width: 100%;
  display: flex;
`;

export const TableHeader = styled.th`
  display: flex;
  justify-content: flex-start;
  color: #777777;
  height: auto;
`;

export const TransRow = styled.tr`
  width: 100%;
  display: flex;
  border-bottom: 1px solid #e1dede;
  padding: 10px 0px;
`;

export const TransTd = styled.td`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  vertical-align: middle;
  align-items: center;
  margin-left: 0rem;
  color: #777777;
  font-size: 15px;
`;

export const ModalHeaderWrapper = styled.div`
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  
  margin: 4rem 2rem 0;
  vertical-align: middle;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin: 2rem 2rem 0;
    text-align: center;
  }

  p {
    margin: 2rem 0;
  }
`;

export const ModalBlurbWrapper = styled.div`
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  margin: 0rem 2rem 0;
  vertical-align: middle;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin: 2rem 2rem 0;
    text-align: center;
  }

  p {
    margin: 2rem 0;
  }
`;

export const ModalRow = styled.tr`
  width: 100%;
  display: flex;
  border-bottom: 1px solid #e1dede;
  border-top: 1px solid #e1dede;
  padding: 10px 0px;
  /* margin-top: 2rem; */
`;

export const ModalTd = styled.td`
  width: 90%;
  display: flex;
  justify-content: space-between;
  vertical-align: middle;
  align-items: center;
  margin-left: 0rem;
  color: #777777;
  font-size: 15px;

`;

export const ModalButtonWrapper = styled.div`
display: flex;
width: 100%;
height: auto;
justify-content: flex-start;
margin: 2rem 0rem;
`

export const ConfirmButton = styled.button`
  background-color: #1572de;
  width: 200px;
  height: 30px;
  border-radius: 4px;
  font-size: 15px;
  outline: none;
  border: none;
  cursor: pointer;
  margin: 0 1rem;
  color: white;
  :hover {
    background-color: #0c55ab;
    transition: ease 0.2s;
  }

  :active {
    border: 1px solid #d6d6d6;
    border-radius: 4px;
  }
`;

export const DenyButton = styled.button`
  background-color: #bf3131;
  width: 200px;
  height: 30px;
  border-radius: 4px;
  font-size: 15px;
  outline: none;
  border: none;
  cursor: pointer;
  color: white;

  :hover {
    background-color: #962727;
    transition: ease 0.2s;
  }

  :active {
    border: 1px solid #d6d6d6;
    border-radius: 4px;
  }
`;

export const UnrecoModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  margin: 4rem 4rem 0;
  vertical-align: middle;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin: 2rem 2rem 0;
    text-align: center;
  }

  p {
    margin: 2rem 0;
  }
`;



export const RecoModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  margin: 4rem 4rem 0;
  vertical-align: middle;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin: 2rem 2rem 0;
    text-align: center;
  }

  p {
    margin: 2rem 0;
  }
`;

export const NotesWrapper = styled.div`
  width: 100%;
  height: 4rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  vertical-align: middle;
`

export const NotesBlurb = styled.p`
  color: #9e9e9e;
  font-size: 14px;
`;

export const ConfirmWrapper = styled.div`
  color: #9e9e9e;
  width: auto;
  height: 2rem;
  display: flex;
  margin: 0 1rem;
  justify-content: flex-start;
`;